import React from "react";
import { Field, Form, withFormik } from "formik";
import { TextField, Switch } from "formik-material-ui";
import { TextField as MUITextField } from "@material-ui/core";
import { roles } from "../../common/codes";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  CircularProgress,
  MenuItem,
  FormControlLabel,
  Chip,
  ListItemText,
  ListItem,
  ListItemIcon,
} from "@material-ui/core";
import { replaceNullByEmptyString, replaceEmptyStringByNull } from "../../../scripts/utils";
import { isEmail } from "../../common/validators";
import styles from "../Organisme/OrganismeForm.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete } from "@material-ui/lab";

const mapStateToProps = (state) => ({
  auth: state.auth,
  app: state.app,
});

class UtilisateurForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      entitesbyRegroupement: [],
      entitesById: {},
      expandedRegroupement: null,
    };
  }

  componentDidMount() {
    const { t } = this.props;

    const selectableEntites = this.isSuperAdmin() ? this.props.entites : this.props.entitesUtilisateur;

    const entitesbyRegroupement = [
      {
        key: null,
        items: selectableEntites.filter((entite) => entite.idtypeentite === 3),
      },
      {
        key: "regions",
        title: t("region.term.plural", "Régions"),
        items: selectableEntites.filter((entite) => entite.idtypeentite === 2),
      },
      {
        key: "departements",
        title: t("departement.term.plural", "Départements"),
        items: selectableEntites.filter((entite) => entite.idtypeentite === 1),
      },
    ];

    const entitesById = this.props.entites.reduce(
      (acc, entite) => ({
        ...acc,
        [entite.identite]: entite,
      }),
      {}
    );

    this.setState({ entitesById, entitesbyRegroupement });
  }

  isEditing = () => this.props.utilisateur != null;

  isSuperAdmin = () => this.props.auth.role === roles.SUPERADMIN;

  isFormEnabled = () =>
    !this.isEditing() ||
    this.isSuperAdmin() ||
    this.props.utilisateur.idOrganismePrincipal === this.props.auth.idOrganismePrincipal ||
    this.props.utilisateur.role === roles.AGRICULTEUR;

  renderTypeUtilisateur = () => {
    const { t } = this.props;

    const allowedTypesUtilisateurs =
      this.isSuperAdmin() || this.isEditing()
        ? this.props.typesUtilisateurs
        : this.props.typesUtilisateurs.filter((typeUtilisateur) =>
            [roles.CONSEILLER, roles.AGRICULTEUR].includes(typeUtilisateur.code)
          );

    return (
      <Field
        component={TextField}
        select
        name="role"
        label={t("admin.user-form.user-type", "Type utilisateur")}
        disabled={!this.isSuperAdmin() && this.isEditing()}
        variant="outlined"
        className="mb-1"
        style={{ minWidth: 200 }}
        required
      >
        {allowedTypesUtilisateurs.map((typeUtilisateur) => (
          <MenuItem key={typeUtilisateur.code} value={typeUtilisateur.code}>
            {typeUtilisateur.libelle}
          </MenuItem>
        ))}
      </Field>
    );
  };

  renderOutrepasserAccesExploitation = () => {
    const { t, values } = this.props;

    if (values.role !== roles.ADMIN_ORGANISME) {
      return null;
    }

    return (
      <FormControlLabel
        control={
          <Field
            type="checkbox"
            component={Switch}
            name="outrepasserAccesExploitation"
            disabled={!this.isSuperAdmin() && this.props.auth.outrepasserAccesExploitation !== true}
            color="primary"
          />
        }
        label={t("admin.user-form.overstep-access-exploitation", "Outrepasser accès exploitation")}
      />
    );
  };

  renderAccessAttributionDroit = () => {
    const { t, values } = this.props;

    const { app } = this.props;
    const isOADPAC = app.urlOAD === window.location.origin;

    if (values.role !== roles.ADMIN_ORGANISME || !isOADPAC) {
      return null;
    }

    return (
      <FormControlLabel
        control={
          <Field
            type="checkbox"
            component={Switch}
            name="accessAttributionDroit"
            disabled={!this.isSuperAdmin()}
            color="primary"
          />
        }
        label={t("admin.user-form.overstep-access-attribution-droit", "Droits supplémentaire administrateur organisme")}
      />
    );
  };

  renderAccessCreationUtilisateur = () => {
    const { t, values } = this.props;

    const { app } = this.props;
    const isOADPAC = app.urlOAD === window.location.origin;

    if (values.role !== roles.ADMIN_ORGANISME || !isOADPAC) {
      return null;
    }

    return (
      <FormControlLabel
        control={
          <Field
            type="checkbox"
            component={Switch}
            name="accessCreationUtilisateur"
            disabled={!this.isSuperAdmin()}
            color="primary"
          />
        }
        label={t("admin.user-form.overstep-access-creation-utilisateur", "Création utilisateurs")}
        className="mb-1"
      />
    );
  };

  renderAccessCreationUpdateExploitation = () => {
    const { t, values } = this.props;

    const { app } = this.props;
    const isOADPAC = app.urlOAD === window.location.origin;

    if (values.role !== roles.CONSEILLER || !isOADPAC) {
      return null;
    }

    return (
      <FormControlLabel
        control={
          <Field
            type="checkbox"
            component={Switch}
            name="accessCreationUpdateExploitation"
            disabled={
              !(this.props.auth.role === roles.ADMIN_ORGANISME && this.props.auth.accessAttributionDroit === true)
            }
            color="primary"
          />
        }
        label={t("admin.user-form.overstep-access-creation-update-exploitation", "Droits supplémentaire conseiller")}
        className="mb-1"
      />
    );
  };

  renderEntites = () => {
    const { t, values, setFieldValue } = this.props;

    if (![roles.SUPERADMIN, roles.ADMIN_ORGANISME, roles.AGRICULTEUR].includes(values.role)) {
      return null;
    }

    const isDisabled = !this.isSuperAdmin() && values.role !== roles.AGRICULTEUR;
    const isMultiple = values.role !== roles.AGRICULTEUR;

    return (
      <Field
        component={TextField}
        name="idsEntites"
        select
        label={t("admin.user-form.region-departement", "Région et département")}
        disabled={isDisabled}
        onChange={(event) => {
          const values = event.target.value.filter((v) => v !== undefined);
          setFieldValue("idsEntites", isMultiple ? values : values.slice(-1));
        }}
        required
        variant="outlined"
        className="mb-1"
        style={{ minWidth: 200 }}
        SelectProps={{
          multiple: true,
          renderValue: (selection) => {
            return (
              <div className="d-flex flex-wrap">
                {selection.map((value) => (
                  <Chip
                    key={value}
                    label={this.state.entitesById[value]?.libelleentite}
                    className={styles.wrapperChip}
                    onDelete={(event) => {
                      setFieldValue(
                        "idsEntites",
                        values.idsEntites.filter((x) => x !== value)
                      );
                    }}
                    onMouseDown={(event) => event.stopPropagation()}
                    disabled={isDisabled}
                  />
                ))}
              </div>
            );
          },
        }}
      >
        {this.state.entitesbyRegroupement.flatMap((regroupement) => {
          const isExpanded = this.state.expandedRegroupement === regroupement.key;
          return [
            regroupement.key && (
              <ListItem
                key={regroupement.key}
                button
                dense
                onClick={(event) => {
                  event.stopPropagation();
                  this.setState((prevState) => ({
                    expandedRegroupement: prevState.expandedRegroupement === regroupement.key ? null : regroupement.key,
                  }));
                }}
              >
                <ListItemText primary={regroupement.title} />
                <ListItemIcon>
                  <FontAwesomeIcon icon={isExpanded ? "chevron-up" : "chevron-down"} />
                </ListItemIcon>
              </ListItem>
            ),
            (!regroupement.key || isExpanded) &&
              regroupement.items.map((entite) => (
                <MenuItem key={entite.identite} value={entite.identite}>
                  {entite.libelleentite}
                </MenuItem>
              )),
          ];
        })}
      </Field>
    );
  };

  renderOrganismePrincipal = () => {
    const { t, values, setFieldValue } = this.props;

    if (![roles.ADMIN_ORGANISME, roles.CONSEILLER].includes(values.role)) {
      return null;
    }

    const allowedOrganismesPrincipaux =
      this.isSuperAdmin() || this.isEditing()
        ? this.props.organismes
        : this.props.organismes.filter((organisme) => organisme.idOrganisme === this.props.auth.idOrganismePrincipal);
    return (
      <Autocomplete
        fullWidth
        options={[...allowedOrganismesPrincipaux, allowedOrganismesPrincipaux.nom]}
        getOptionLabel={(organisme) => (organisme != null ? organisme.nom : "")}
        defaultValue={values.organismePrincipal}
        onChange={(event, value) => {
          setFieldValue("idOrganismePrincipal", value?.idOrganisme || "");
        }}
        renderInput={(params) => (
          <MUITextField
            {...params}
            name="idOrganismePrincipal"
            className="mb-1"
            variant="outlined"
            fullWidth
            label={t("admin.user-form.main-organization", "Organisme principal")}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    );
  };

  renderOrganismeSecondaire = () => {
    const { t, values, setFieldValue } = this.props;

    if (values.role !== roles.CONSEILLER) {
      return null;
    }

    return (
      <Autocomplete
        fullWidth
        disabled={!this.isSuperAdmin()}
        options={[...this.props.organismes, this.props.organismes.nom]}
        getOptionLabel={(organisme) => (organisme != null ? organisme.nom : "")}
        defaultValue={values.organismeSecondaire}
        onChange={(event, value) => {
          setFieldValue("idOrganismeSecondaire", value?.idOrganisme || "");
        }}
        renderInput={(params) => (
          <MUITextField
            {...params}
            name="idOrganismeSecondaire"
            className="mb-1"
            variant="outlined"
            fullWidth
            label={t("admin.user-form.secondary-organization", "Organisme secondaire")}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    );
  };

  renderDemarches = () => {
    const { t, values } = this.props;

    if (values.role !== roles.CONSEILLER && values.role !== roles.AGRICULTEUR) {
      return null;
    }

    const visibleDemarches = this.props.demarches.filter(
      (demarche) => demarche.selectable || values.idsDemarches.includes(demarche.idDemarche)
    );

    return (
      <Field
        component={TextField}
        select
        name="idsDemarches"
        label={t("admin.user-form.procedures", "Démarches")}
        disabled={!this.isFormEnabled()}
        SelectProps={{
          multiple: true,
          renderValue: (selected) => (
            <div className="d-flex flex-wrap">
              {selected
                .filter((value) => visibleDemarches.find((demarche) => demarche.idDemarche === value))
                .map((value) => (
                  <Chip
                    key={value}
                    label={visibleDemarches.find((demarche) => demarche.idDemarche === value)?.libelle}
                    className={styles.wrapperChip}
                  />
                ))}
            </div>
          ),
        }}
        variant="outlined"
        className="mb-1"
        style={{ minWidth: 200 }}
      >
        {visibleDemarches.map((demarche) => (
          <MenuItem key={demarche.idDemarche} value={demarche.idDemarche}>
            {demarche.libelle}
          </MenuItem>
        ))}
      </Field>
    );
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { values, setFieldValue } = this.props;

    if (prevProps.values.role !== values.role) {
      setFieldValue("idOrganismeSecondaire", "");
      setFieldValue("idsDemarches", []);
      setFieldValue("idsEntites", []);

      if (values.role === roles.SUPERADMIN) {
        setFieldValue("outrepasserAccesExploitation", true);
      } else {
        setFieldValue("outrepasserAccesExploitation", false);
      }

      if (values.role === roles.SUPERADMIN && values.role === roles.ADMIN_ORGANISME) {
        setFieldValue("accessAttributionDroit", true);
        setFieldValue("accessCreationUtilisateur", true);
      } else {
        setFieldValue("accessAttributionDroit", false);
        setFieldValue("accessCreationUtilisateur", false);
      }

      if (this.props.auth.role === roles.ADMIN_ORGANISME && values.role === roles.CONSEILLER) {
        setFieldValue("idOrganismePrincipal", this.props.auth.idOrganismePrincipal);
        setFieldValue("accessCreationUpdateExploitation", true);
      } else {
        setFieldValue("idOrganismePrincipal", "");
        setFieldValue("accessCreationUpdateExploitation", false);
      }
    }
  }

  render() {
    const { t, isSubmitting, isValid } = this.props;

    return (
      <Form className="d-flex flex-column align-items-start">
        <Field
          component={TextField}
          name="email"
          type="email"
          label={t("admin.user-form.email", "Email :")}
          disabled={!this.isFormEnabled()}
          variant="outlined"
          className="mb-1"
          required
        />
        <Field
          component={TextField}
          name="prenom"
          type="text"
          label={t("admin.user-form.firstname", "Prénom :")}
          disabled={!this.isFormEnabled()}
          variant="outlined"
          className="mb-1"
          required
        />
        <Field
          component={TextField}
          name="nom"
          type="text"
          label={t("admin.user-form.lastname", "Nom :")}
          disabled={!this.isFormEnabled()}
          variant="outlined"
          className="mb-1"
          required
        />
        {this.renderTypeUtilisateur()}
        {this.renderEntites()}
        {this.renderOutrepasserAccesExploitation()}
        {this.renderAccessAttributionDroit()}
        {this.renderAccessCreationUtilisateur()}
        {this.renderAccessCreationUpdateExploitation()}
        {this.renderOrganismePrincipal()}
        {this.renderOrganismeSecondaire()}
        {this.renderDemarches()}
        {this.isFormEnabled() && (
          <Button
            disabled={!isValid || isSubmitting}
            color="primary"
            variant="contained"
            type="submit"
            className="mt-1"
          >
            {isSubmitting ? <CircularProgress size={24} /> : t("admin.user-form.validate", "Valider")}
          </Button>
        )}
      </Form>
    );
  }
}

const validate = (values, props) => {
  const { t } = props;
  const errors = {};

  if (!values.email) {
    errors.email = t("admin.user-form.required-email", "L'adresse email est obligatoire");
  } else if (!isEmail(values.email)) {
    errors.email = t("admin.user-form.invalid-email", "L'adresse email est invalide");
  }

  if (!values.prenom) {
    errors.prenom = t("admin.user-form.required-firstname", "Le prénom est obligatoire");
  }

  if (!values.nom) {
    errors.nom = t("admin.user-form.required-lastname", "Le nom est obligatoire");
  }

  if (
    [roles.SUPERADMIN, roles.ADMIN_ORGANISME, roles.AGRICULTEUR].includes(values.role) &&
    values.idsEntites.length === 0
  ) {
    errors.idsEntites = t(
      "admin.user-form.required-region-departement",
      "Le filtre région et département est obligatoire"
    );
  }

  if ([roles.ADMIN_ORGANISME, roles.CONSEILLER].includes(values.role)) {
    if (!values.idOrganismePrincipal) {
      errors.idOrganismePrincipal = t("admin.user-form.required-organization", "L'organisme principal est obligatoire");
    } else if (parseInt(values.idOrganismeSecondaire) === parseInt(values.idOrganismePrincipal)) {
      errors.idOrganismeSecondaire = t(
        "admin.user-form.different-organizations",
        "L'organisme secondaire doit être différent de l'organisme principal"
      );
    }
  }

  return errors;
};

const mapPropsToValues = (props) =>
  replaceNullByEmptyString(
    {
      email: "",
      prenom: "",
      nom: "",
      role: roles.AGRICULTEUR,
      idOrganismePrincipal: "",
      idOrganismeSecondaire: "",
      outrepasserAccesExploitation: false,
      accessCreationUpdateExploitation: false,
      accessCreationUtilisateur: false,
      accessAttributionDroit: false,
      idsEntites: [],
      idsDemarches: [],
      ...props.utilisateur,
    },
    ["idOrganismePrincipal", "idOrganismeSecondaire"]
  );

const handleSubmit = async (values, { props, setSubmitting }) => {
  values = replaceEmptyStringByNull(values, ["idOrganismePrincipal", "idOrganismeSecondaire"]);
  await props.onSubmit(values);
  setSubmitting(false);
};

export default connect(mapStateToProps)(
  withRouter(
    withTranslation()(
      withFormik({
        mapPropsToValues: mapPropsToValues,
        validate: validate,
        handleSubmit: handleSubmit,
      })(UtilisateurForm)
    )
  )
);
