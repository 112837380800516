import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import codes from "../../../../common/codes";
import DatePicker from "../../../../common/DatePicker";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class OADPACResults extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      etatDossier: "",
      dateAudit: null,
      commentaireAuditeur: "",
    };
  }

  componentDidMount() {
    const { exploitationDemarcheInfo } = this.props;

    this.setState({
      dateAudit: exploitationDemarcheInfo.exploitationDemarche.dateAudit ?? null,
      commentaireAuditeur: exploitationDemarcheInfo.exploitationDemarcheMillesime.commentaireAuditeur ?? "",
    });
  }

  changeField = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const { t, saveResult, userRole } = this.props,
      { etatDossier, commentaireAuditeur, dateAudit } = this.state;
    return (
      <Card className="d-flex flex-fill flex-column">
        <CardHeader id="scrollToTop" title={<Typography variant={"h3"}>Résultats</Typography>} />
        <CardContent style={{ overflowY: "auto" }}>
          <Box className={"d-flex justify-around"}>
            <TextField
              select
              fullWidth={false}
              label={t("hve3a.etat-dossier", "État du dossier")}
              className={"mt-1 mr-1"}
              style={{ minWidth: "10rem" }}
              name={"etatDossier"}
              value={etatDossier}
              onChange={this.changeField}
              disabled={userRole === codes.roles.AGRICULTEUR}
            >
              {codes.procedureStates.map(({ libelle, code }, i) => (
                <MenuItem key={i} value={code}>
                  {libelle}
                </MenuItem>
              ))}
            </TextField>
            <DatePicker
              disableToolbar
              variant="inline"
              format="DD/MM/yyyy"
              margin="normal"
              label={"Date de l'audit"}
              value={dateAudit}
              disabled={userRole === codes.roles.AGRICULTEUR}
              onChange={(value) =>
                this.changeField({
                  target: {
                    name: "dateAudit",
                    value: value?.format("YYYY-MM-DD") || null,
                  },
                })
              }
            />
          </Box>
          <Box className={"d-flex justify-around"}>
            <TextField
              multiline={true}
              rows={3}
              label={"Commentaire de l'auditeur"}
              className={"mt-1 mr-1"}
              name={"commentaireAuditeur"}
              value={commentaireAuditeur}
              disabled={userRole === codes.roles.AGRICULTEUR}
              onChange={this.changeField}
            />
          </Box>

          <div className={"d-flex justify-center"}>
            <Tooltip title={"L'application est en lecture seule"}>
              <div>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  // disabled={(etatDossier == null || dateAudit == null)}
                  disabled={true}
                  className={"mt-1"}
                  onClick={() =>
                    saveResult({
                      etatDossier,
                      dateAudit,
                      commentaireAuditeur,
                    })
                  }
                >
                  Editer le résultat
                </Button>
              </div>
            </Tooltip>
          </div>
        </CardContent>
      </Card>
    );
  }
}

OADPACResults.propTypes = {
  indicateurs: PropTypes.object.isRequired,
  rubriques: PropTypes.array.isRequired,
  saveResult: PropTypes.func.isRequired,
  exploitationDemarcheInfo: PropTypes.object.isRequired,
};

export default compose(withTranslation(), connect(mapStateToProps))(OADPACResults);
