import React from "react";
import Indicator from "../../Indicator";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import DatePicker from "../../../../../common/DatePicker";

class Annee extends React.Component {
  render() {
    const {
      indicateur,
      onChangeIndicator,
      sousRubriqueIndex,
      libelleEnglobantIndex,
      changeIndicateur,
      demarche,
    } = this.props;

    const valueRendered = (
      <DatePicker
        views={["year"]}
        style={{ maxWidth: "8rem" }}
        margin="normal"
        format="yyyy"
        label={"Année"}
        maxDateMessage={"La date ne doit pas être supérieur à la date maximale"}
        value={indicateur.valeur.value}
        onChange={(event) =>
          changeIndicateur(
            {
              ...indicateur,
              valeur: { value: typeof event === "object" && event !== null ? event.format("yyyy") : null },
            },
            sousRubriqueIndex,
            libelleEnglobantIndex
          )
        }
      />
    );

    return (
      <Indicator indicateur={indicateur} onChange={onChangeIndicator} demarche={demarche}>
        {valueRendered}
      </Indicator>
    );
  }
}

export default compose(withTranslation())(Annee);
