import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography, Box, Tooltip, IconButton } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import AdminTableHead from "./Table/AdminTableHead";
import AdminTable from "./Table/AdminTable";
import network from "../../actions/external/network";
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SigaModal from "../common/SigaModal";
import { AccesModal } from "./Acces/Acces";
import confirm from "../common/confirm";
import { roles } from "../common/codes";
import ModifExploitation from "../Main/ModifExploitation/ModifExploitation";

const mapStateToProps = (state) => ({
  idMillesime: state.millesime.selected.idMillesime,
  auth: state.auth,
  organisme: state.organisme.selected,
  app: state.app,
});

class Exploitations extends React.Component {
  config = {
    columns: [
      {
        id: "raisonSociale",
        label: "Raison sociale",
      },
      {
        id: "siret",
        label: "Siret",
      },
      {
        id: "pacage",
        label: "Pacage",
      },
      {
        id: "adresse",
        label: "Adresse",
      },
      {
        id: "commune",
        label: "Commune",
      },
      {
        id: "actions",
        label: "",
        type: AdminTableHead.columnsTypes.ACTION,
      },
    ],
  };

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
    if (props.app.urlOAD === window.location.origin) {
      this.config.columns.splice(5, 0, { id: "nomOrganisme", label: "Organisme" });
    }

    this.state = {
      data: [],
      count: 0,
      openModalExploitation: false,
      openModalAcces: false,
      idExploitationModal: null,
      modalData: {},
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { app } = this.props;
    const isOADPAC = app.urlOAD === window.location.origin;
    if (isOADPAC && prevProps?.organisme?.idOrganisme !== this.props?.organisme?.idOrganisme) {
      this.tableRef.current.loadData();
    }
  }

  loadData = async ({ paramsUrl }) => {
    const { t } = this.props;
    const { app } = this.props;
    const isOADPAC = app.urlOAD === window.location.origin;
    const idOrganisme = isOADPAC ? `&idOrganisme=${this.props.organisme.idOrganisme}` : "";
    try {
      const response = await network.fetch(`/api/exploitations?${paramsUrl}${idOrganisme}`);
      this.setState({
        data: response.data,
        count: response.count,
      });
    } catch (error) {
      toastr.error(t("error", "Erreur"), t("admin.farms.load-error", "Erreur lors du chargement des données"));
    }
  };

  onSelect = async (exploitation) => {
    this.setState({
      openModalExploitation: true,
      idExploitationModal: exploitation.idExploitation,
    });
  };

  onHandleAcces = (id) => {
    this.setState({
      openModalAcces: true,
      idExploitationModal: id,
    });
  };

  onCreate = () => {
    this.setState({
      openModalExploitation: true,
      idExploitationModal: null,
    });
  };

  onDelete = async (ids, event) => {
    event.stopPropagation();
    const { t } = this.props;

    if (!(await confirm(t("admin.farms.delete-confirm", "Êtes-vous sûr de vouloir supprimer cette exploitation ?")))) {
      return;
    }

    try {
      await Promise.all(
        ids.map((id) =>
          network.fetch(`/api/exploitations/${id}`, {
            method: "DELETE",
          })
        )
      );

      this.tableRef.current.loadData();

      toastr.success("OK", t("admin.farms.delete-success", "Suppression effectuée"));
    } catch (error) {
      toastr.error(
        t("error", "Erreur"),
        t("admin.farms.delete-error", "Une erreur est survenue lors de la suppression")
      );
    }
  };

  getRows = () => {
    const { t } = this.props;
    return this.state.data.map((aData) => ({
      ...aData,
      commune: [aData.commune.nom].filter((value) => value != null),
      adresse: [aData.adresse, aData.adresse2, aData.commune.codepostal].filter((value) => value != null).join(" "),
      actions: (
        <React.Fragment>
          <Tooltip title={t("admin.edit", "Modifier")}>
            <IconButton onClick={() => this.onSelect(aData)}>
              <FontAwesomeIcon icon="pencil-alt" />
            </IconButton>
          </Tooltip>
          {this.props.auth.role !== roles.CONSEILLER && (
            <Tooltip title={t("admin.handle-access", "Gérer les accès")}>
              <IconButton onClick={() => this.onHandleAcces(aData.idExploitation)}>
                <FontAwesomeIcon icon="key" />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={t("admin.delete", "Supprimer")}>
            <IconButton onClick={(event) => this.onDelete([aData.idExploitation], event)}>
              <FontAwesomeIcon icon="trash-alt" />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ),
    }));
  };

  handleCloseModal = () => {
    this.setState({
      openModalExploitation: false,
      openModalAcces: false,
      idExploitationModal: null,
      modalData: {},
    });
  };

  afterSubmitFromModal = () => {
    this.tableRef.current.loadData();
    this.handleCloseModal();
  };

  render() {
    const { t } = this.props,
      { openModalExploitation, idExploitationModal, openModalAcces, count } = this.state;
    return (
      <Box className="ml-2 w-100">
        <Typography variant="h3" className="mb-2">
          {t("farms", "Exploitations")}
        </Typography>
        <AdminTable
          idColumn="idExploitation"
          columns={this.config.columns}
          rows={this.getRows()}
          count={count}
          loadData={this.loadData}
          onCreate={this.onCreate}
          ref={this.tableRef}
        />
        <AccesModal
          showModal={openModalAcces}
          onCloseModal={this.handleCloseModal}
          idExploitation={idExploitationModal}
          afterSubmit={this.afterSubmitFromModal}
        />
        <SigaModal
          open={openModalExploitation}
          onClose={this.handleCloseModal}
          title={<Typography variant="h2">{t("admin.exploitation", "Exploitation")}</Typography>}
          fullWidth={true}
          maxWidth={"lg"}
        >
          <ModifExploitation
            idExploitation={idExploitationModal}
            afterSubmit={this.afterSubmitFromModal}
            onCancel={this.handleCloseModal}
          />
        </SigaModal>
      </Box>
    );
  }
}

export default withRouter(connect(mapStateToProps)(withTranslation()(Exploitations)));
