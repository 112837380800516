import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { logout } from "../../../actions/auth";
import { setSelectedMillesime } from "../../../actions/millesime";
import { setSelectedOrganisme } from "../../../actions/organisme";
import {
  AppBar,
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withStyles } from "@material-ui/styles";
import styles from "./Menu.module.scss";
import { setDarkMode } from "../../../actions/config";
import { roles } from "../../common/codes";
import moment from "moment";
import MenuToolbar from "./MenuToolbar";
import withTheme from "@material-ui/core/styles/withTheme";

const mapStateToProps = (state) => ({
  app: state.app,
  config: state.config,
  millesime: state.millesime,
  exploitation: state.exploitation,
  organismes: state.organisme,
  auth: state.auth,
  darkMode: state.config.darkMode,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  setSelectedMillesime: (millesime) => dispatch(setSelectedMillesime(millesime)),
  setSelectedOrganisme: (organisme) => dispatch(setSelectedOrganisme(organisme)),
  setDarkMode: (isDarkmode) => dispatch(setDarkMode(isDarkmode)),
});

const SelectMillesime = withStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: "1.4em",
    minWidth: "4em",
    minHeight: 0,
  },
  icon: {
    color: theme.palette.common.white,
  },
}))(Select);

const SelectOrganisme = withStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: "1.4em",
    minWidth: "4em",
    minHeight: 0,
  },
  icon: {
    color: theme.palette.common.white,
  },
}))(Select);

const SelectLanguage = withStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    fontSize: "1.3em",
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  icon: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
}))(Select);

const iconChevronDown = <FontAwesomeIcon className="ml-1" icon="caret-down" style={{ fontSize: "1.1em " }} />;

class Menu extends React.Component {
  static defaultProps = {
    isAdmin: false,
  };

  handleLogout = (event) => {
    event.preventDefault();
    this.props.logout();
  };

  handleChangeMillesime = (event) => {
    this.props.setSelectedMillesime(event.target.value);
  };

  handleChangeOrganisme = (event) => {
    this.props.setSelectedOrganisme(event.target.value);
  };

  handleChangeLanguage = (value) => {
    this.props.i18n.changeLanguage(value);
    moment.locale(value);
  };

  renderLeftToolbar = () => {
    const { config, isAdmin, app } = this.props;
    const logo = config.logoBase64;

    const isOADPAC = app.urlOAD === window.location.origin;
    return (
      <div className={styles.leftToolbar}>
        <Button component={Link} to="/">
          <img src={logo} alt={config.titleSite} title={config.titleSite} className={styles.logo} />
        </Button>
        {app.environnement !== "prod" && (
          <Chip
            style={{ zIndex: "2", margin: "0 1rem 0 -3rem" }}
            color={"primary"}
            label={<Typography variant={"h4"}>{"Serveur de " + app.environnement}</Typography>}
          />
        )}
        {!isAdmin && (
          <React.Fragment>
            {this.renderExploitation()}
            {this.renderMillesime()}
          </React.Fragment>
        )}

        {isOADPAC && <React.Fragment>{this.renderOrganisme()}</React.Fragment>}
      </div>
    );
  };

  renderExploitation = () => {
    const { t, exploitation, location, theme } = this.props;
    return (
      <Tooltip title={t("menu.change-exploitation", "Changer d'exploitation")} arrow placement="bottom">
        <Box
          className="d-flex flex-column px-1 justify-center"
          style={{ background: `${theme.palette.third.main}`, height: "100%" }}
        >
          <span className={styles.labelExploitation}>{exploitation.selected ? "Exploitation" : ""}</span>
          <Button
            className={styles.buttonExploitation}
            title={t("menu.change-exploitation", `Changer d'exploitation`)}
            {...(!location.pathname.includes("change-exploitation")
              ? {
                  component: Link,
                  to: "/change-exploitation",
                }
              : {})}
            color="inherit"
          >
            <span>{exploitation.selected ? exploitation.selected.raisonSociale : "Rechercher une exploitation"}</span>
            {iconChevronDown}
          </Button>
        </Box>
      </Tooltip>
    );
  };

  renderMillesime = () => {
    const { t, millesime, theme } = this.props;
    return (
      <Tooltip title={t("menu.change-millesime", "Campagne")} arrow placement="bottom">
        <Box
          className="d-flex flex-column justify-center px-1"
          style={{ background: `${theme.palette.secondary.main}`, height: "100%" }}
        >
          <FormControl>
            <span className={styles.labelExploitation}>{t("menu.millesime", "Campagne")}</span>
            <SelectMillesime
              value={millesime.list.find((m) => m.idMillesime === millesime.selected.idMillesime)}
              onChange={this.handleChangeMillesime}
              disableUnderline
              variant="standard"
            >
              {millesime.list.map((item) => (
                <MenuItem key={item.idMillesime} value={item}>
                  {item.idMillesime}
                </MenuItem>
              ))}
            </SelectMillesime>
          </FormControl>
        </Box>
      </Tooltip>
    );
  };

  renderOrganisme = () => {
    const { t, organismes, theme } = this.props;
    if (this.props.auth.role === roles.CONSEILLER || this.props.auth.role === roles.SUPERADMIN) {
      return (
        <Tooltip title={t("menu.change-organisme", "Organisme")} arrow placement="bottom">
          <Box
            className="d-flex flex-column justify-center px-1"
            style={{ background: `${theme.palette.secondary.main}`, height: "100%" }}
          >
            <FormControl>
              <span className={styles.labelExploitation}>{t("menu.organisme", "Organisme")}</span>
              <SelectOrganisme
                value={organismes.list.find((m) => m.idOrganisme === organismes.selected.idOrganisme)}
                onChange={this.handleChangeOrganisme}
                disableUnderline
                variant="standard"
              >
                {organismes.list.map((item) => (
                  <MenuItem key={item.idOrganisme} value={item}>
                    {item.nom}
                  </MenuItem>
                ))}
              </SelectOrganisme>
            </FormControl>
          </Box>
        </Tooltip>
      );
    }
  };

  renderDarkMode = () => {
    const { t, setDarkMode, darkMode } = this.props;
    return (
      <Tooltip
        title={darkMode ? t("menu.light", "Passer au mode clair") : t("menu.dark", "Passer au mode sombre")}
        arrow
        placement="bottom"
      >
        <Box className="mx-1">
          <IconButton size="small" color="inherit" onClick={() => setDarkMode(!darkMode)}>
            <FontAwesomeIcon icon={darkMode ? "sun" : "moon"} />
          </IconButton>
        </Box>
      </Tooltip>
    );
  };

  renderAdmin = () => {
    const { app } = this.props;
    const isOADPAC = app.urlOAD === window.location.origin;

    if (
      ![roles.SUPERADMIN, roles.ADMIN_ORGANISME].includes(this.props.auth.role) &&
      !(
        [roles.CONSEILLER].includes(this.props.auth.role) &&
        isOADPAC &&
        this.props.auth.accessCreationUpdateExploitation === true
      )
    ) {
      return null;
    }

    const { t } = this.props;
    return (
      <Tooltip title={t("menu.admin", "Administration")} arrow placement="bottom">
        <Box className="mx-1">
          <IconButton size="small" color="inherit" component={Link} to="/admin">
            <FontAwesomeIcon icon="wrench" />
          </IconButton>
        </Box>
      </Tooltip>
    );
  };

  renderLanguage = () => {
    const { t } = this.props;

    const countries = [
      {
        code: "fr",
        traduction: "fr",
      },
      {
        code: "gb",
        traduction: "en",
      },
    ];

    function countryToFlag(isoCode) {
      return typeof String.fromCodePoint !== "undefined"
        ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
    }

    return (
      <Tooltip title={t("menu.change-language", "Changer de langue")} arrow placement="left">
        <Box className="mx-1">
          <FormControl>
            <SelectLanguage
              value={countries.find((item) => item.traduction === this.props.i18n.language)}
              onChange={(event, value) => value != null && this.handleChangeLanguage(event.target.value.traduction)}
              variant="standard"
              disableUnderline
            >
              {countries.map((item) => (
                <MenuItem value={item} key={item.code}>
                  <span>{countryToFlag(item.code)}</span>
                </MenuItem>
              ))}
            </SelectLanguage>
          </FormControl>
        </Box>
      </Tooltip>
    );
  };

  renderLogout = () => {
    const { t } = this.props;
    return (
      <Tooltip title={t("menu.logout", "Déconnexion")} arrow placement="bottom">
        <Box className="mx-1">
          <IconButton onClick={this.handleLogout} color="inherit" size="small">
            <FontAwesomeIcon icon="power-off" />
          </IconButton>
        </Box>
      </Tooltip>
    );
  };

  renderAccount = () => {
    const { t } = this.props;
    return (
      <Tooltip title={t("account.title", "Mon compte")} arrow placement="bottom">
        <Button component={Link} to="/account" color="inherit" className="mx-1">
          <span className="mr-2">{this.props.auth.prenom + " " + this.props.auth.nom}</span>
          <FontAwesomeIcon icon="user" className="user" />
        </Button>
      </Tooltip>
    );
  };

  renderHome = () => {
    const { t } = this.props;
    return (
      <Tooltip title={t("menu.home", "Accueil")} arrow placement="bottom">
        <IconButton size="small" className="mx-1" component={Link} to="/" color="inherit">
          <FontAwesomeIcon icon="home" />
        </IconButton>
      </Tooltip>
    );
  };

  render() {
    return (
      <AppBar position="static">
        <MenuToolbar className={styles.toolbar}>
          {this.renderLeftToolbar()}
          <div className="d-flex justify-center align-items-center">
            {this.renderAccount()}
            {this.renderHome()}
            {this.renderLogout()}
            {this.renderAdmin()}
            {this.renderDarkMode()}
            {/*{this.renderLanguage()}*/}
          </div>
        </MenuToolbar>
      </AppBar>
    );
  }
}

export default withRouter(withTheme(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Menu))));
