import React from "react";
import Menu from "./Menu/Menu";
import { Paper } from "@material-ui/core";
import { loadMillesimes } from "../../actions/millesime";
import { loadOrganismes } from "../../actions/organisme";
import { connect } from "react-redux";
import Footer from "./Footer/Footer";

const mapStateToProps = (state) => ({
  millesime: state.millesime,
  organisme: state.organisme,
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  loadMillesimes: () => dispatch(loadMillesimes()),
  loadOrganismes: () => dispatch(loadOrganismes()),
});

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.state = {
      childKey: 0,
    };
  }

  componentDidMount = () => {
    this.props.loadMillesimes();
    this.props.loadOrganismes();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.millesime.selected?.idMillesime !== this.props.millesime.selected?.idMillesime) {
      this.setState((prevState) => ({ childKey: prevState.childKey + 1 }));
    }
    if (prevProps.organisme.selected?.idOrganisme !== this.props.organisme.selected?.idOrganisme) {
      this.setState((prevState) => ({ childKey: prevState.childKey }));
    }
  }

  render() {
    const { app } = this.props;
    const isOADPAC = app.urlOAD === window.location.origin;
    return (
      this.props.millesime?.selected &&
      this.props.organisme?.selected && (
        <Paper className="paper" square>
          <Menu />
          <div className="page">
            {React.cloneElement(this.props.children, {
              key: this.state.childKey,
              wrappedComponentRef: this.componentRef,
            })}
          </div>
          {isOADPAC && <Footer />}
        </Paper>
      )
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
