import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControlLabel,
  MenuItem,
  Radio,
  Typography,
} from "@material-ui/core";
import { Field, Form, withFormik } from "formik";
import { RadioGroup, Switch, TextField } from "formik-material-ui";
import DocumentInput from "../Documents/DocumentInput";
import { typesDocumentsCodes } from "../../common/codes";
import network from "../../../actions/external/network";
import {
  replaceEmptyStringByNull,
  replaceMomentObjectByString,
  replaceNullByEmptyString,
} from "../../../scripts/utils";
import DatePicker from "../../common/DatePicker";
import HVE1 from "./HVE1";
import HVE2 from "./HVE2";
import { roles } from "../../common/codes";
import { Alert, AlertTitle } from "@material-ui/lab";
import { connect } from "react-redux";
import OADPACStatic from "./LegacyV1/OAD_PAC/OADPACStatic";
import HVE4Static from "./HVE4/HVE4Static";

const mapStateToProps = (state) => ({
  auth: state.auth,
});

class DemarcheForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organisme: null,
      mounted: false,
    };
  }

  componentDidMount() {
    const { setFieldValue, auth, demarche } = this.props;
    this.setState({ mounted: true });
    this.loadOrganisme();

    if (auth.role !== roles.SUPERADMIN && demarche.code === OADPACStatic.codeNational) {
      setFieldValue("exploitationDemarcheMillesime.idOrganisme", auth.idOrganismePrincipal);
    }
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  componentDidUpdate(prevProps, prevState) {
    const { values, setFieldValue } = this.props;

    if (
      prevProps.values.exploitationDemarcheMillesime.idOrganisme !== values.exploitationDemarcheMillesime.idOrganisme
    ) {
      this.loadOrganisme();
    }

    if (
      prevProps.values.exploitationDemarche.estCertificationEquivalente !==
      values.exploitationDemarche.estCertificationEquivalente
    ) {
      if (values.exploitationDemarche.estCertificationEquivalente) {
        setFieldValue("exploitationDemarche.certificationEquivalenteEstTotale", "true");
      } else {
        setFieldValue("exploitationDemarche.certificationEquivalenteEstTotale", null);
        setFieldValue("exploitationDemarche.certificationEquivalenteType", "");
        setFieldValue("exploitationDemarche.certificationEquivalenteDateCertification", null);
        setFieldValue("exploitationDemarche.certificationEquivalenteDateAudit", null);
        setFieldValue("exploitationDemarche.certificationEquivalenteDocument", null);
      }
    }
  }

  loadOrganisme = async () => {
    const idOrganisme = this.props.values.exploitationDemarcheMillesime.idOrganisme;
    let organisme = idOrganisme === "" ? null : await network.fetch(`/api/organismes/${idOrganisme}`);
    this.setState({ organisme });
  };

  renderOrganismeCertificateur = () => {
    const { t, organismes, values, auth, demarche } = this.props;
    const { organisme } = this.state;
    return (
      <Box className="d-flex flex-column mb-2">
        {demarche.code === OADPACStatic.codeNational ? (
          <Typography variant="h3" className="mb-2">
            {t("demarche.gestion-demarche", "Gesion de la démarche")}
          </Typography>
        ) : (
          <Typography variant="h3" className="mb-2">
            {t("demarche.organisme-certificateur", "Organisme")}
          </Typography>
        )}

        <Box className="d-flex">
          <Box className="flex-fill d-flex flex-column align-items-start">
            <Field
              component={TextField}
              select
              name="exploitationDemarcheMillesime.idOrganisme"
              label={t("demarche.organisme-certificateur", "Organisme")}
              variant="outlined"
              className="mb-1"
              fullWidth={false}
              style={{ width: 250 }}
              disabled={auth.role !== roles.SUPERADMIN && demarche.code === OADPACStatic.codeNational}
            >
              <MenuItem value="">
                <em>{t("none.term.singular", "Aucun")}</em>
              </MenuItem>
              {organismes.map((organisme) => (
                <MenuItem key={organisme.idOrganisme} value={organisme.idOrganisme}>
                  {organisme.nom}
                </MenuItem>
              ))}
            </Field>
            <img
              src={organisme?.logoBase64}
              style={{
                maxHeight: "50px",
                margin: "1em",
              }}
              alt=""
            />
          </Box>
          <Box className="flex-fill d-flex flex-column">
            <Typography variant="h5" className="mb-1">
              {t("demarche.contrat-organisme-certificateur", "Contrat ou engagement avec l'organisme certificateur")}
            </Typography>
            <DocumentInput
              document={values.contratOrganisme}
              typeDocumentCode={typesDocumentsCodes.CONTRAT_ORGANISME}
              onSelect={(document) => {
                this.props.setFieldValue("exploitationDemarcheMillesime.contratOrganisme", document.idDocument);
                this.props.setFieldValue("contratOrganisme", document);
              }}
              onRemove={() => {
                this.props.setFieldValue("exploitationDemarcheMillesime.contratOrganisme", null);
                this.props.setFieldValue("contratOrganisme", null);
              }}
            />
          </Box>
        </Box>
        {demarche.code === HVE4Static.codeNational && (
          <Box className="flex-fill d-flex flex-column align-items-start">
            <Box className="align-items-start flex-fill d-flex">
              <Field
                component={TextField}
                className="mb-1"
                name="exploitationDemarcheMillesime.certificateurExterne"
                label={t("demarche.certificateur-externe", "Certificateur externe")}
                variant="outlined"
                fullWidth={false}
                style={{ width: 250 }}
                disabled={auth.role !== roles.SUPERADMIN && demarche.code === OADPACStatic.codeNational}
              ></Field>
              <Field
                component={TextField}
                className="ml-3 mb-1"
                name="exploitationDemarcheMillesime.nomAuditeurExterne"
                label={t("demarche.nom-auditeur-externe", "Nom")}
                variant="outlined"
                fullWidth={false}
                style={{ width: 250 }}
                disabled={auth.role !== roles.SUPERADMIN && demarche.code === OADPACStatic.codeNational}
              ></Field>
              <Field
                component={TextField}
                className="ml-3 mb-1"
                name="exploitationDemarcheMillesime.prenomAuditeurExterne"
                label={t("demarche.prenom-auditeur-externe", "Prénom")}
                variant="outlined"
                fullWidth={false}
                style={{ width: 250 }}
                disabled={auth.role !== roles.SUPERADMIN && demarche.code === OADPACStatic.codeNational}
              ></Field>
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  renderGestionCertification = () => {
    const { t, values, demarche } = this.props;
    const typeCertification = [
      {
        value: "false",
        libelle: "Individuelle",
      },
      {
        value: "true",
        libelle: "Collective",
      },
    ];
    const typeAudit = [
      {
        code: "INITIAL",
        libelle: "Initial",
      },
      {
        code: "SUIVI",
        libelle: "Suivi",
      },
      {
        code: "RENOUVELLEMENT",
        libelle: "Renouvellement",
      },
    ];
    const natureAudit = [
      {
        code: null,
        libelle: "",
      },
      {
        code: "INTERNE",
        libelle: "Interne",
      },
      {
        code: "EXTERNE",
        libelle: "Externe",
      },
    ];

    return (
      <Box className="d-flex flex-column mt-2 mb-2">
        <Typography variant="h3" className="mb-2">
          {t("demarche.gestion-certification", "Gestion de la certification")}
        </Typography>
        <Box className="d-flex">
          <Box className="flex-fill d-flex flex-column align-items-start">
            <div className="d-flex mr-2">
              <FormControlLabel
                control={
                  <Field
                    component={DatePicker}
                    name="exploitationDemarcheMillesime.dateEntree"
                    invalidDateMessage={t("invalid-date", "Date invalide")}
                  />
                }
                label={
                  <Typography style={{ width: 250 }}>
                    {t("demarche.date-entree-certification", "Date d'entrée dans la certification") + " *"}
                  </Typography>
                }
                labelPlacement="start"
                className="mt-1"
              />
              <FormControlLabel
                control={
                  <Field
                    component={DatePicker}
                    name="exploitationDemarcheMillesime.dateAudit"
                    invalidDateMessage={t("invalid-date", "Date invalide")}
                  />
                }
                label={<Typography style={{ width: 250 }}>{t("demarche.date-audit", "Date de l'audit")}</Typography>}
                labelPlacement="start"
                className="mt-1 ml-3"
              />
            </div>
            {demarche.code === HVE4Static.codeNational && (
              <div className="d-flex mr-2">
                <FormControlLabel
                  control={
                    <Field
                      component={TextField}
                      select
                      name="exploitationDemarche.estCollective"
                      variant="outlined"
                      fullWidth={false}
                      style={{ width: 200 }}
                    >
                      {typeCertification.map((certification) => (
                        <MenuItem key={certification.value} value={certification.value}>
                          {certification.libelle}
                        </MenuItem>
                      ))}
                    </Field>
                  }
                  label={
                    <Typography style={{ width: 250 }}>
                      {t("demarche.type-certification", "Type de certification")}
                    </Typography>
                  }
                  labelPlacement="start"
                  className="mt-1"
                />
                <FormControlLabel
                  control={
                    <Field
                      component={TextField}
                      select
                      defaultValue={typeAudit[0].code}
                      name="exploitationDemarche.typeAudit"
                      variant="outlined"
                      fullWidth={false}
                      style={{ width: 200 }}
                    >
                      {typeAudit.map((audit) => (
                        <MenuItem key={audit.code} value={audit.code}>
                          {audit.libelle}
                        </MenuItem>
                      ))}
                    </Field>
                  }
                  label={<Typography style={{ width: 250 }}>{t("demarche.type-audit", "Type d'audit")}</Typography>}
                  labelPlacement="start"
                  className="mt-1 ml-3"
                />
              </div>
            )}
            <div className="d-flex mr-2">
              <FormControlLabel
                control={
                  <Field
                    component={DatePicker}
                    name="exploitationDemarcheMillesime.periodeAuditeeDebut"
                    invalidDateMessage={t("invalid-date", "Date invalide")}
                  />
                }
                label={
                  <Typography style={{ width: 250 }}>
                    {t("demarche.periode-auditee-debut", "Début de la période auditée")}
                  </Typography>
                }
                labelPlacement="start"
                className="mt-1"
              />
              <FormControlLabel
                control={
                  <Field
                    component={DatePicker}
                    name="exploitationDemarcheMillesime.periodeAuditeeFin"
                    invalidDateMessage={t("invalid-date", "Date invalide")}
                  />
                }
                label={
                  <Typography style={{ width: 250 }}>
                    {t("demarche.periode-auditee-fin", "fin de la période auditée")}
                  </Typography>
                }
                labelPlacement="start"
                className="mt-1 ml-3"
              />
            </div>
            <div className="d-flex mr-2">
              {demarche.code === HVE4Static.codeNational && (
                <FormControlLabel
                  control={
                    <Field
                      component={TextField}
                      name={"exploitationDemarche.dureeAudit"}
                      fullWidth={false}
                      style={{ width: 200 }}
                    />
                  }
                  className="mt-1"
                  label={
                    <Typography style={{ width: 250 }}>{t("demarche.duree-audit", "Durée de l'audit")}</Typography>
                  }
                  labelPlacement={"start"}
                />
              )}
              <FormControlLabel
                control={
                  <Field
                    component={DatePicker}
                    name="exploitationDemarcheMillesime.dateCertification"
                    invalidDateMessage={t("invalid-date", "Date invalide")}
                  />
                }
                label={
                  <Typography style={{ width: 250 }}>
                    {t("demarche.date-certification", "Date de la certification")}
                  </Typography>
                }
                labelPlacement="start"
                className={demarche.code === HVE4Static.codeNational ? "mt-1 ml-3" : "mt-1"}
              />
            </div>
            <Field component={RadioGroup} name={"exploitationDemarche.estCollective"} className="mt-2">
              <FormControlLabel
                value={"false"}
                control={<Radio color="primary" />}
                label={t("demarche.certification-individuelle", "Certification individuelle")}
              />
              <FormControlLabel
                value={"true"}
                control={<Radio color="primary" />}
                label={t("demarche.certification-collective", "Certification collective")}
              />
            </Field>
            {values.exploitationDemarche.estCollective === "true" && demarche.code === HVE4Static.codeNational && (
              <React.Fragment>
                <div className={"d-flex mr-2"}>
                  <FormControlLabel
                    control={
                      <Field
                        component={TextField}
                        select
                        defaultValue={natureAudit[0].code}
                        name="exploitationDemarcheMillesime.natureAudit"
                        variant="outlined"
                        fullWidth={false}
                        style={{ width: 200 }}
                      >
                        {natureAudit.map(
                          (audit) =>
                            audit.code && (
                              <MenuItem key={audit.code} value={audit.code}>
                                {audit.libelle}
                              </MenuItem>
                            )
                        )}
                      </Field>
                    }
                    label={
                      <Typography style={{ width: 250 }}>{t("demarche.nature-audit", "Nature de l'audit")}</Typography>
                    }
                    labelPlacement="start"
                    className="mt-1"
                  />
                </div>
                <div className={"d-flex mr-2"}>
                  <FormControlLabel
                    control={
                      <Field
                        component={TextField}
                        name="exploitationDemarcheMillesime.nomStructurePorteuse"
                        fullWidth={false}
                        style={{ width: 200 }}
                      />
                    }
                    label={
                      <Typography style={{ width: 250 }}>
                        {t("demarche.nom-structure-porteuse", "Nom de la structure porteuse")}
                      </Typography>
                    }
                    labelPlacement="start"
                    className={demarche.code === HVE4Static.codeNational ? "mt-1" : "mt-1"}
                  />
                </div>
                <div className={"d-flex mr-2"}>
                  <FormControlLabel
                    control={
                      <Field
                        component={TextField}
                        name="exploitationDemarcheMillesime.adresseAuditeurExterne"
                        fullWidth={false}
                        style={{ width: 200 }}
                      />
                    }
                    label={
                      <Typography style={{ width: 250 }}>
                        {t("demarche.adresse-auditeur-externe", "Adresse")}
                      </Typography>
                    }
                    labelPlacement="start"
                    className={demarche.code === HVE4Static.codeNational ? "mt-1" : "mt-1"}
                  />
                  <FormControlLabel
                    control={
                      <Field
                        component={TextField}
                        name="exploitationDemarcheMillesime.adresse2AuditeurExterne"
                        fullWidth={false}
                        style={{ width: 200 }}
                      />
                    }
                    label={
                      <Typography style={{ width: 250 }}>
                        {t("demarche.adresse2-auditeur-externe", "Adresse complémentaire")}
                      </Typography>
                    }
                    labelPlacement="start"
                    className={demarche.code === HVE4Static.codeNational ? "mt-1 ml-3" : "mt-1"}
                  />
                </div>
                <div className={"d-flex mr-2"}>
                  <FormControlLabel
                    control={
                      <Field
                        component={TextField}
                        name="exploitationDemarcheMillesime.codePostalAuditeurExterne"
                        fullWidth={false}
                        style={{ width: 200 }}
                      />
                    }
                    label={
                      <Typography style={{ width: 250 }}>
                        {t("demarche.code-postal-auditeur-externe", "Code postal")}
                      </Typography>
                    }
                    labelPlacement="start"
                    className={demarche.code === HVE4Static.codeNational ? "mt-1" : "mt-1"}
                  />
                  <FormControlLabel
                    control={
                      <Field
                        component={TextField}
                        name="exploitationDemarcheMillesime.communeAuditeurExterne"
                        fullWidth={false}
                        style={{ width: 200 }}
                      />
                    }
                    label={
                      <Typography style={{ width: 250 }}>
                        {t("demarche.commune-auditeur-externe", "Commune")}
                      </Typography>
                    }
                    labelPlacement="start"
                    className={demarche.code === HVE4Static.codeNational ? "mt-1 ml-3" : "mt-1"}
                  />
                </div>
              </React.Fragment>
            )}
            {demarche.code === HVE1.codeNational && (
              <FormControlLabel
                control={
                  <Field component={Switch} type="checkbox" name="exploitationDemarche.aidePac" color="primary" />
                }
                label={t("demarche.aides-pac", "Exploitation bénéficiant des aides PAC")}
                className="mt-1"
              />
            )}
          </Box>
          {demarche.code !== HVE4Static.codeNational && (
            <Box className="flex-fill mx-2">
              <h3>Certification niveau 1</h3>
              <div className="d-flex flex-column align-items-start">
                <FormControlLabel
                  control={
                    <Field
                      component={Switch}
                      type="checkbox"
                      name="exploitationDemarche.hve1Atteinte"
                      color="primary"
                    />
                  }
                  labelPlacement="start"
                  label={
                    <Typography style={{ width: 250 }}>
                      {t("demarche.certification-niveau-1-atteinte", "Certification niveau 1 atteinte")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Field
                      component={DatePicker}
                      name="exploitationDemarche.hve1Date"
                      invalidDateMessage={t("invalid-date", "Date invalide")}
                    />
                  }
                  label={
                    <Typography style={{ width: 250 }}>
                      {t("demarche.date-certification-niveau-1", "Date de certification de niveau 1")}
                    </Typography>
                  }
                  className="mt-1"
                  labelPlacement="start"
                />
                <Box className="flex-fill d-flex flex-column mt-1">
                  <DocumentInput
                    name="exploitationDemarche.documentHve1"
                    document={values.documentHve1}
                    onSelect={(document) => {
                      let listDocuments = this.props.values.documentHve1 || [];
                      listDocuments.push(document);
                      this.props.setFieldValue("exploitationDemarche.documentHve1", listDocuments);
                      this.props.setFieldValue("documentHve1", listDocuments);
                    }}
                    onRemove={(index) => {
                      let listDocument = [
                        ...values.documentHve1.slice(0, index),
                        ...values.documentHve1.slice(index + 1),
                      ];
                      this.props.setFieldValue("exploitationDemarche.documentHVE1", listDocument);
                      this.props.setFieldValue("documentHve1", listDocument);
                    }}
                  />
                </Box>
                <h3 className="mt-3">Certification niveau 2</h3>
                <FormControlLabel
                  control={
                    <Field
                      component={Switch}
                      type="checkbox"
                      name="exploitationDemarche.hve2Atteinte"
                      color="primary"
                    />
                  }
                  labelPlacement="start"
                  label={
                    <Typography style={{ width: 250 }}>
                      {t("demarche.certification-niveau-2-atteinte", "Certification niveau 2 atteinte")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Field
                      component={DatePicker}
                      name="exploitationDemarche.hve2Date"
                      invalidDateMessage={t("invalid-date", "Date invalide")}
                    />
                  }
                  label={
                    <Typography style={{ width: 250 }}>
                      {t("demarche.date-certification-niveau-2", "Date de certification de niveau 2")}
                    </Typography>
                  }
                  className="mt-1"
                  labelPlacement="start"
                />
                <Box className="flex-fill d-flex flex-column mt-1">
                  <DocumentInput
                    name="exploitationDemarche.documentHve2"
                    document={values.documentHve2}
                    onSelect={(document) => {
                      let listDocuments = this.props.values.documentHve2 || [];
                      listDocuments.push(document);
                      this.props.setFieldValue("exploitationDemarche.documentHve2", listDocuments);
                      this.props.setFieldValue("documentHve2", listDocuments);
                    }}
                    onRemove={(index) => {
                      let listDocument = [
                        ...values.documentHve2.slice(0, index),
                        ...values.documentHve2.slice(index + 1),
                      ];
                      this.props.setFieldValue("exploitationDemarche.documentHVE2", listDocument);
                      this.props.setFieldValue("documentHve2", listDocument);
                    }}
                  />
                </Box>
                <Field
                  className="mt-3"
                  component={TextField}
                  name="exploitationDemarche.commentaireGeneral"
                  label={t("demarche.commentaire-general", "Commentaire général")}
                  multiline
                  rows={8}
                  variant="outlined"
                />
              </div>
            </Box>
          )}
        </Box>
        <Box className="d-flex mt-2 justify-between">
          {values.exploitationDemarche.estCollective === "true" && (
            <Box className="d-flex flex-column flex-fill">
              <Typography variant="h5" className="mb-1">
                {t("demarche.contrat-structure-collective", "Contrat avec la structure collective")}
              </Typography>
              <DocumentInput
                document={values.contratStructureCollective}
                typeDocumentCode={typesDocumentsCodes.CONTRAT_STRUCTURE_COLLECTIVE}
                onSelect={(document) => {
                  this.props.setFieldValue("exploitationDemarche.contratStructureCollective", document.idDocument);
                  this.props.setFieldValue("contratStructureCollective", document);
                }}
                onRemove={() => {
                  this.props.setFieldValue("exploitationDemarche.contratStructureCollective", null);
                  this.props.setFieldValue("contratStructureCollective", null);
                }}
              />
            </Box>
          )}
          <Box className="d-flex flex-column flex-fill">
            <Typography variant="h5" className="mb-1">
              {t("demarche.fiche-annuaire", "Fiche annuaire")}
            </Typography>
            <DocumentInput
              document={values.ficheAnnuaire}
              typeDocumentCode={typesDocumentsCodes.FICHE_ANNUAIRE}
              onSelect={(document) => {
                this.props.setFieldValue("exploitationDemarche.ficheAnnuaire", document.idDocument);
                this.props.setFieldValue("ficheAnnuaire", document);
              }}
              onRemove={() => {
                this.props.setFieldValue("exploitationDemarche.ficheAnnuaire", null);
                this.props.setFieldValue("ficheAnnuaire", null);
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  renderCertificationsExploitation = () => {
    const { t } = this.props;
    return (
      <Box className="d-flex flex-column mt-2 mb-2">
        <Typography variant="h3" className="mb-2">
          {t("demarche.certifications-exploitation", "Certifications de l'exploitation")}
        </Typography>
        <Box className="flex-fill mx-2">
          <h3>Niveau 1</h3>
          <div className="d-flex align-items-start">
            <FormControlLabel
              control={
                <Field component={Switch} type="checkbox" name="exploitationDemarche.hve1Atteinte" color="primary" />
              }
              labelPlacement="start"
              style={{ height: 51 }}
              label={
                <Typography style={{ width: 250 }}>
                  {t("demarche.certification-niveau-1-atteinte", "Certification niveau 1 atteinte")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Field
                  component={DatePicker}
                  name="exploitationDemarche.hve1Date"
                  invalidDateMessage={t("invalid-date", "Date invalide")}
                />
              }
              label={
                <Typography style={{ width: 250 }}>
                  {t("demarche.date-certification-niveau-1", "Date de certification de niveau 1")}
                </Typography>
              }
              className="ml-3"
              labelPlacement="start"
            />
            <FormControlLabel
              control={<Field component={TextField} name="exploitationDemarche.structureValidanteN1" />}
              label={
                <Typography style={{ width: 250 }}>
                  {t("demarche.structure-validante-n1", "Structure validatrice N1")}
                </Typography>
              }
              className="ml-3"
              labelPlacement="start"
            />
          </div>
          <h3 className="mt-3">Niveau 2</h3>
          <div className="d-flex align-items-start flex-wrap">
            <FormControlLabel
              control={
                <Field component={Switch} type="checkbox" name="exploitationDemarche.hve2Atteinte" color="primary" />
              }
              labelPlacement="start"
              style={{ height: 51 }}
              label={
                <Typography style={{ width: 250 }}>
                  {t("demarche.certification-niveau-2-atteinte", "Certification niveau 2 atteinte")}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Field
                  component={DatePicker}
                  name="exploitationDemarche.hve2Date"
                  invalidDateMessage={t("invalid-date", "Date invalide")}
                />
              }
              label={
                <Typography style={{ width: 250 }}>
                  {t("demarche.date-certification-niveau-2", "Date de certification de niveau 2")}
                </Typography>
              }
              className="ml-3"
              labelPlacement="start"
            />
            <FormControlLabel
              control={<Field component={TextField} name="exploitationDemarche.orgaCertifN2" />}
              label={
                <Typography style={{ width: 250 }}>
                  {t("demarche.orga-certif-n2", "Organisme certificateur N2")}
                </Typography>
              }
              className="ml-3"
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <Field component={Switch} type="checkbox" name="exploitationDemarche.equivalenceN2" color="primary" />
              }
              labelPlacement="start"
              style={{ height: 51 }}
              label={
                <Typography style={{ width: 250 }}>{t("demarche.equivalence-n2", "Equivalence niveau 2")}</Typography>
              }
              className="ml-3"
            />
            <FormControlLabel
              control={<Field component={TextField} name="exploitationDemarche.nomDemarcheN2" />}
              label={<Typography style={{ width: 250 }}> {t("demarche.nom-demarche-n2", "Nom démarche")}</Typography>}
              labelPlacement="start"
            />
          </div>
          <h3 className="mt-3">Niveau 3</h3>
          <div className="d-flex align-items-start flex-wrap">
            <FormControlLabel
              control={
                <Field
                  component={DatePicker}
                  name="exploitationDemarche.hve3Date"
                  invalidDateMessage={t("invalid-date", "Date invalide")}
                />
              }
              label={
                <Typography style={{ width: 250 }}>
                  {t("demarche.date-certification-niveau-3", "Date 1ère certification Niveau 3")}
                </Typography>
              }
              labelPlacement="start"
            />
            <FormControlLabel
              control={<Field component={TextField} name="exploitationDemarche.versReferentielN3" />}
              label={
                <Typography style={{ width: 250 }}>
                  {t("demarche.version-referentiel-n3", "Version du référentiel")}
                </Typography>
              }
              className="ml-3"
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <Field
                  component={TextField}
                  inputProps={{ pattern: "[0-9]{4}" }}
                  name="exploitationDemarche.hve3CampPrec"
                />
              }
              label={
                <Typography style={{ width: 250 }}>
                  {t("demarche.campagne-precedente-hve3", "Dernière campagne évaluée")}
                </Typography>
              }
              className="ml-3"
              labelPlacement="start"
            />
          </div>
          <h3 className="mt-3">Autres démarches</h3>
          <div className="d-flex align-items-start flex-wrap">
            <FormControlLabel
              control={<Field component={TextField} name="exploitationDemarche.autreDemarche1" />}
              label={
                <Typography style={{ width: 250 }}>{t("demarche.autre-demarche-1", "Autre démarche 1")}</Typography>
              }
              labelPlacement="start"
            />
            <FormControlLabel
              control={<Field component={TextField} name="exploitationDemarche.autreDemarche2" />}
              label={
                <Typography style={{ width: 250 }}>{t("demarche.autre-demarche-2", "Autre démarche 2")}</Typography>
              }
              className="ml-3"
              labelPlacement="start"
            />
            <FormControlLabel
              control={<Field component={TextField} name="exploitationDemarche.autreDemarche3" />}
              label={
                <Typography style={{ width: 250 }}>{t("demarche.autre-demarche-3", "Autre démarche 3")}</Typography>
              }
              className="ml-3"
              labelPlacement="start"
            />
          </div>
          <Field
            className="mt-3"
            component={TextField}
            name="exploitationDemarche.commentaireGeneral"
            label={t("demarche.commentaire-general", "Commentaire général")}
            multiline
            rows={8}
            variant="outlined"
          />
        </Box>
      </Box>
    );
  };

  renderCertificationEquivalente = () => {
    const { t, certificationsEquivalentes, values } = this.props;

    const estCertificationEquivalente = values.exploitationDemarche.estCertificationEquivalente;

    return (
      <fieldset className="mt-2" style={estCertificationEquivalente ? {} : { border: 0 }}>
        <legend>
          <FormControlLabel
            control={
              <Field
                component={Switch}
                type="checkbox"
                name="exploitationDemarche.estCertificationEquivalente"
                color="primary"
              />
            }
            label={
              <Typography variant="h5">
                {t("demarche.avec-equivalence", "Certification obtenue avec une équivalence")}
              </Typography>
            }
          />
        </legend>
        {estCertificationEquivalente && (
          <Box className="d-flex flex-column align-items-start">
            <FormControlLabel
              control={
                <Field
                  component={TextField}
                  select
                  name="exploitationDemarche.certificationEquivalenteEstTotale"
                  variant="outlined"
                  className="mb-1"
                  fullWidth={false}
                  style={{ width: 250 }}
                >
                  {[
                    {
                      id: "true",
                      libelle: "Totale",
                    },
                    {
                      id: "false",
                      libelle: "Partielle",
                    },
                  ].map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.libelle}
                    </MenuItem>
                  ))}
                </Field>
              }
              label={
                <Typography style={{ width: 200 }}>{t("certification.term.singular", "Certification")}</Typography>
              }
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <Field
                  component={TextField}
                  select
                  name="exploitationDemarche.certificationEquivalenteType"
                  variant="outlined"
                  className="mb-1"
                  fullWidth={false}
                  style={{ width: 250 }}
                >
                  {certificationsEquivalentes.map((certificationEquivalente) => (
                    <MenuItem
                      key={certificationEquivalente.idCertificationEquivalente}
                      value={certificationEquivalente.idCertificationEquivalente}
                    >
                      {certificationEquivalente.libelle}
                    </MenuItem>
                  ))}
                </Field>
              }
              label={
                <Typography style={{ width: 200 }}>
                  {t("demarche.type-certification", "Type de certification")}
                </Typography>
              }
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <Field
                  component={DatePicker}
                  name="exploitationDemarche.certificationEquivalenteDateCertification"
                  invalidDateMessage={t("invalid-date", "Date invalide")}
                />
              }
              label={
                <Typography style={{ width: 200 }}>
                  {t("demarche.date-certification", "Date de la certification")}
                </Typography>
              }
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <Field
                  component={DatePicker}
                  name="exploitationDemarche.certificationEquivalenteDateAudit"
                  invalidDateMessage={t("invalid-date", "Date invalide")}
                />
              }
              label={<Typography style={{ width: 200 }}>{t("demarche.date-audit", "Date de l'audit")}</Typography>}
              labelPlacement="start"
              className="mt-1"
            />
            <Box className="d-flex flex-column mt-2">
              <Typography variant="h5" className="mb-1">
                {t("demarche.document-certification-equivalente", "Document de certification équivalente")}
              </Typography>
              <DocumentInput
                document={values.certificationEquivalenteDocument}
                typeDocumentCode={typesDocumentsCodes.CERTIFICATION_EQUIVALENTE}
                onSelect={(document) => {
                  this.props.setFieldValue(
                    "exploitationDemarche.certificationEquivalenteDocument",
                    document.idDocument
                  );
                  this.props.setFieldValue("certificationEquivalenteDocument", document);
                }}
                onRemove={() => {
                  this.props.setFieldValue("exploitationDemarche.certificationEquivalenteDocument", null);
                  this.props.setFieldValue("certificationEquivalenteDocument", null);
                }}
              />
            </Box>
          </Box>
        )}
      </fieldset>
    );
  };

  render() {
    const { t, demarche, isSubmitting, isValid } = this.props;

    return (
      <React.Fragment>
        <Typography variant="h1" className="mb-2">
          {demarche.libelle}
        </Typography>
        <Card className="flex-fill">
          <CardContent>
            <Form className="d-flex flex-column">
              {this.renderOrganismeCertificateur()}
              {demarche.code !== OADPACStatic.codeNational && (
                <React.Fragment>
                  <Divider />
                  {this.renderGestionCertification()}
                </React.Fragment>
              )}
              {demarche.code === HVE4Static.codeNational && (
                <React.Fragment>
                  <Divider />
                  {this.renderCertificationsExploitation()}
                </React.Fragment>
              )}
              {[HVE1.codeNational, HVE2.codeNational].includes(demarche.code) && (
                <React.Fragment>
                  <Divider />
                  {this.renderCertificationEquivalente()}
                </React.Fragment>
              )}

              <Button
                disabled={!isValid || isSubmitting}
                color="primary"
                variant="contained"
                type="submit"
                className="mt-1 align-self-center"
              >
                {isSubmitting ? <CircularProgress size={24} /> : t("validate", "Valider")}
              </Button>
              {!isValid && (
                <Alert severity="error" className="mt-1">
                  <AlertTitle>
                    <strong>{t("form-missing-data.title", "Impossible de valider")}</strong>
                  </AlertTitle>
                  {t(
                    "form-missing-data.message",
                    "Des données sont manquantes ou erronnées lors de la saisie du formulaire."
                  )}
                </Alert>
              )}
            </Form>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}

const validate = (values, props) => {
  const { t, demarche } = props;
  const errors = {};
  if (demarche.code !== OADPACStatic.codeNational) {
    if (!values.exploitationDemarcheMillesime.dateEntree) {
      errors.exploitationDemarcheMillesime = errors.exploitationDemarcheMillesime || {};
      errors.exploitationDemarcheMillesime.dateEntree = t(
        "demarche.date-entree-obligatoire",
        "La date d'entrée dans la certification est obligatoire"
      );
    }
  }

  return errors;
};

const mapPropsToValues = (props) => {
  let {
    exploitationDemarche,
    exploitationDemarcheMillesime,
    contratOrganisme,
    documentHve1,
    documentHve2,
    contratStructureCollective,
    ficheAnnuaire,
    certificationEquivalenteDocument,
  } = props.exploitationDemarcheInfo;

  exploitationDemarche = replaceNullByEmptyString(
    {
      hve1Date: null,
      hve2Date: null,
      hve1Atteinte: false,
      hve2Atteinte: false,
      estCollective: false,
      aidePac: false,
      commentaireGeneral: "",
      contratStructureCollective: null,
      documentHve1: null,
      documentHve2: null,
      ficheAnnuaire: null,
      estCertificationEquivalente: false,
      certificationEquivalenteEstTotale: null,
      certificationEquivalenteType: null,
      certificationEquivalenteDateCertification: null,
      certificationEquivalenteDateAudit: null,
      certificationEquivalenteDocument: null,
      ...exploitationDemarche,
    },
    ["certificationEquivalenteType"]
  );
  exploitationDemarche.estCollective = String(exploitationDemarche.estCollective);

  exploitationDemarcheMillesime = replaceNullByEmptyString(
    {
      idOrganisme: null,
      contratOrganisme: null,
      dateEntree: null,
      dateAudit: null,
      dateCertification: null,
      periodeAuditeeDebut: null,
      periodeAuditeeFin: null,
      ...exploitationDemarcheMillesime,
    },
    ["idOrganisme"]
  );

  return {
    exploitationDemarche,
    exploitationDemarcheMillesime,
    contratOrganisme,
    documentHve1,
    documentHve2,
    contratStructureCollective,
    ficheAnnuaire,
    certificationEquivalenteDocument,
  };
};

const handleSubmit = async (values, { props, setSubmitting }) => {
  const exploitationDemarche = replaceMomentObjectByString(
    replaceEmptyStringByNull(values.exploitationDemarche, ["certificationEquivalenteType"])
  );
  const exploitationDemarcheMillesime = replaceMomentObjectByString(
    replaceEmptyStringByNull(values.exploitationDemarcheMillesime, ["idOrganisme"])
  );

  const documentHve1 = values.documentHve1;
  const documentHve2 = values.documentHve2;

  await props.onSubmit({
    exploitationDemarche,
    exploitationDemarcheMillesime,
    documentHve1,
    documentHve2,
  });
  if (this.state.mounted) {
    setSubmitting(false);
  }
};

export default connect(mapStateToProps)(
  withRouter(
    withTranslation()(
      withFormik({
        mapPropsToValues: mapPropsToValues,
        mapStateToProps: mapStateToProps,
        validate: validate,
        handleSubmit: handleSubmit,
      })(DemarcheForm)
    )
  )
);
