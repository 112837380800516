import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ConditionalRoute from "./ConditionalRoute";
import { roles } from "../common/codes";

const mapStateToProps = (state, ownProps) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isAdmin: [roles.SUPERADMIN, roles.ADMIN_ORGANISME].includes(state.auth.role),
  app: state.app,
  auth: state.auth,
});

const AdminRoute = ({ children, isAuthenticated, isAdmin, app, auth, ...rest }) => {
  const isOADPAC = app.urlOAD === window.location.origin;

  const conditions = [
    {
      test: () => isAuthenticated,
      redirectTo: "/login",
    },
    {
      test: () =>
        isAdmin ||
        ([roles.CONSEILLER].includes(auth.role) && isOADPAC && auth.accessCreationUpdateExploitation === true),
      redirectTo: "/",
    },
  ];
  return (
    <ConditionalRoute {...rest} conditions={conditions}>
      {children}
    </ConditionalRoute>
  );
};

export default withRouter(connect(mapStateToProps)(AdminRoute));
