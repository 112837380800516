import React from "react";
import Indicator from "../../Indicator";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Box, TextField } from "@material-ui/core";

class InfosExploitation extends React.Component {
  componentDidMount() {
    const { exploitation } = this.props;
    this.saveValeurIndicateur({
      value: {
        raisonSociale: exploitation.raisonSociale,
        departement: exploitation.idCommune.substring(0, 2),
      },
    });
  }

  saveValeurIndicateur = (valeur) => {
    const { indicateur, sousRubriqueIndex, libelleEnglobantIndex, changeIndicateur } = this.props;
    changeIndicateur(
      {
        ...indicateur,
        valeur: {
          ...indicateur.valeur,
          ...valeur,
        },
      },
      sousRubriqueIndex,
      libelleEnglobantIndex
    );
  };

  render() {
    const { t, indicateur, onChangeIndicator, demarche } = this.props;

    const valueRendered = (
      <Box>
        <TextField
          label={t("oad-pac.raison-sociale", "Raison sociale")}
          fullWidth={false}
          value={indicateur.valeur.value?.raisonSociale}
          style={{ width: "15rem" }}
          disabled
        />
        <TextField
          label={t("oad-pac.numero-departement", "Numéro de département")}
          fullWidth={false}
          value={indicateur.valeur.value?.departement.substring(0, 2)}
          style={{ width: "6rem", marginLeft: "2rem" }}
          disabled
        />
      </Box>
    );

    return (
      <Indicator indicateur={indicateur} onChange={onChangeIndicator} demarche={demarche}>
        {valueRendered}
      </Indicator>
    );
  }
}

export default compose(withTranslation())(InfosExploitation);
