import React from "react";
import Indicator from "../../Indicator";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { MenuItem, TextField } from "@material-ui/core";

class ListeOrganisationSecteurTomate extends React.Component {
  render() {
    const {
      indicateur,
      onChangeIndicator,
      sousRubriqueIndex,
      libelleEnglobantIndex,
      changeIndicateur,
      valueInputProps,
      computedFields,
      demarche,
    } = this.props;

    const listeOrganisations = [
      {
        libelle: "Union des Coopératives « France PRUNE »",
        value: "FRANCE_PRUNE",
      },
      {
        libelle: "Union des Coopératives « Union des Pruniculteurs de France » (U.P.F.)",
        value: "UPF",
      },
      {
        libelle: "S.I.C.A. « Prunidor »",
        value: "PRUNIDOR",
      },
      {
        libelle: "Coop « Cabso »",
        value: "CABSO",
      },
      {
        libelle: "Union des pruniculteurs Individuels (U.P.I.)",
        value: "UPI",
      },
      {
        libelle: "Syndicat des producteurs de pruneaux d'Agen (SYNPPA)",
        value: "SYNPPA",
      },
      {
        libelle: "Syndicat des pruniculteurs sécheurs indépendants (SYPRUSI)",
        value: "SYPRUSI",
      },
      {
        libelle: "(SARL SUD OUEST BIO) Syndicat Sud Ouest Bio",
        value: "SUD_OUEST_BIO",
      },
      {
        libelle: "SCA Vivacoop",
        value: "VICACOOP",
      },
      {
        libelle: "",
        value: "",
      },
    ];

    const valueRendered = (
      <TextField
        fullWidth={false}
        InputProps={valueInputProps}
        select
        value={indicateur.valeur.value}
        style={{
          fontWeight: indicateur.afficherGras ? "bold" : "normal",
          width: "20rem",
        }}
        disabled={computedFields.includes(indicateur.codeNational)}
        onChange={(event) =>
          changeIndicateur(
            { ...indicateur, valeur: { value: event.target.value } },
            sousRubriqueIndex,
            libelleEnglobantIndex
          )
        }
      >
        {listeOrganisations.map((element) => (
          <MenuItem key={element.value} value={element.value}>
            {element.libelle}
          </MenuItem>
        ))}
      </TextField>
    );

    return (
      <Indicator indicateur={indicateur} onChange={onChangeIndicator} demarche={demarche}>
        {valueRendered}
      </Indicator>
    );
  }
}

export default compose(withTranslation())(ListeOrganisationSecteurTomate);
