import React from "react";
import { IconButton, MenuItem, Select, Switch, TableCell, TableRow, TextField } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import NumberInput from "../../common/NumberInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import propTypes from "prop-types";
import { withTranslation } from "react-i18next";
import DragAndDrop from "./DragAndDrop";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state) => ({
  app: state.app,
  idExploitation: state.exploitation.selected,
});

class AssolementRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dragging: false,
    };
  }

  render() {
    const {
      showAffectationColumn,
      assolement,
      culturesOptions,
      precisionsOptions,
      categoriesCultures,
      typeCulturesFiltered,
      hasExploitationTierce,
      handleCultureChange,
      handleCategorieChange,
      handlePrecisionChange,
      handleTypeChange,
      onChange,
      onKeyDown,
      deleteLine,
      doubleRow,
      app,
    } = this.props;

    const isOADPAC = app.urlOAD === window.location.origin;
    const isCVI = this.props.idExploitation?.exploitationFamilleCultures?.VITIC?.code === "CVI";
    const filterOptions = createFilterOptions({
      matchFrom: "start",
    });

    return (
      <TableRow style={{ verticalAlign: "top" }}>
        <TableCell>
          <Autocomplete
            value={assolement.culture}
            options={[...culturesOptions, assolement.culture]}
            filterOptions={filterOptions}
            getOptionLabel={(culture) =>
              culture != null ? (!culture.estMajeure ? culture.libelle + " *" : culture.libelle) : ""
            }
            getOptionSelected={(option, value) => option.idCulture === value.idCulture}
            onChange={handleCultureChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </TableCell>
        {isOADPAC && (
          <React.Fragment>
            <TableCell style={{ width: "17rem" }}>
              <Select
                value={
                  assolement.culture &&
                  (assolement.precision ||
                    precisionsOptions[assolement.culture?.codePacDefaut.substring(0, 3)].find(
                      (precision) => precision.cle === assolement.culture?.codePacDefaut
                    ))
                }
                variant="outlined"
                disabled={
                  !assolement.culture || precisionsOptions[assolement.culture?.codePacDefaut.substring(0, 3)].length < 2
                }
                style={{ width: "17rem" }}
                onChange={handlePrecisionChange}
              >
                {assolement.culture &&
                  precisionsOptions[assolement.culture?.codePacDefaut.substring(0, 3)].length > 1 &&
                  precisionsOptions[assolement.culture?.codePacDefaut.substring(0, 3)]?.map((p) => (
                    <MenuItem key={p.cle} value={p}>
                      {p.libelle}
                    </MenuItem>
                  ))}
              </Select>
            </TableCell>
            <TableCell style={{ width: "17rem" }}>
              <div className={"d-flex justify-center"}>
                <span style={{ alignSelf: "center" }}>Non</span>
                <Switch checked={assolement.prodSemence} disabled />
                <span style={{ alignSelf: "center" }}>Oui</span>
              </div>
            </TableCell>
            <TableCell style={{ width: "17rem" }}>
              <TextField value={assolement.caractDestination || ""} disabled />
            </TableCell>
          </React.Fragment>
        )}
        {!isOADPAC && (
          <React.Fragment>
            <TableCell style={{ width: "25rem" }}>
              <TextField
                select
                defaultValue=""
                value={assolement.categorieCulture || ""}
                disabled={!assolement.culture}
                onChange={handleCategorieChange}
              >
                <MenuItem value={""}>
                  <em>Sélectionner</em>
                </MenuItem>
                {categoriesCultures.map((categorie) => (
                  <MenuItem key={categorie.idCategorieCulture} value={categorie}>
                    {categorie.libelle}
                  </MenuItem>
                ))}
              </TextField>
            </TableCell>
            <TableCell style={{ width: "25rem" }}>
              <TextField
                select
                defaultValue=""
                value={assolement.typeCulture || ""}
                disabled={!assolement.culture || !assolement.categorieCulture}
                onChange={handleTypeChange}
                error={!(!assolement.culture || !assolement.categorieCulture) && doubleRow}
                helperText={
                  !(!assolement.culture || !assolement.categorieCulture) && doubleRow
                    ? "Ligne d'assolement en double"
                    : ""
                }
              >
                <MenuItem value={""}>
                  <em>Aucune</em>
                </MenuItem>
                {typeCulturesFiltered.map((type) => (
                  <MenuItem key={type.idTypeCulture} value={type}>
                    {type.libelle}
                  </MenuItem>
                ))}
              </TextField>
            </TableCell>
          </React.Fragment>
        )}
        {hasExploitationTierce && (
          <React.Fragment>
            {showAffectationColumn ? (
              <TableCell style={{ width: "20rem" }}>
                <DragAndDrop
                  assolement={assolement}
                  parcelles={assolement.parcelles}
                  moveParcelleToAssolement={this.props.moveParcelleToAssolement}
                />
              </TableCell>
            ) : (
              <TableCell style={{ width: "6rem" }}>
                <NumberInput
                  value={assolement.parcelles.length > 0 ? assolement.parcelles.length : 0}
                  variant="outlined"
                  disabled
                />
              </TableCell>
            )}
            <TableCell style={{ width: "7rem" }}>
              <NumberInput
                numberOfDecimals={4}
                value={
                  isCVI && assolement?.typeCulture?.code === "VIGNES"
                    ? assolement.parcelles.map((p) => p.surfaceCvi).reduce((acc, surface) => acc + surface, 0)
                    : assolement.parcelles.map((p) => p.surface).reduce((acc, surface) => acc + surface, 0)
                }
                variant="outlined"
                disabled
              />
            </TableCell>
          </React.Fragment>
        )}
        <TableCell style={{ width: "7rem" }}>
          <NumberInput
            numberOfDecimals={4}
            value={assolement.surface}
            variant="outlined"
            onChange={onChange}
            onKeyDown={onKeyDown}
            disabled={!assolement.culture}
            error={!!assolement.culture && !!assolement.surface <= 0}
            helperText={!!assolement.culture && assolement.surface <= 0 ? "Doit être supérieur à 0" : ""}
          />
        </TableCell>
        <TableCell style={{ width: "1rem" }}>
          <IconButton tabIndex={-1} onClick={deleteLine}>
            <FontAwesomeIcon icon="trash-alt" />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}

AssolementRow.propTypes = {
  assolement: propTypes.shape({
    culture: propTypes.object,
    categorieCulture: propTypes.oneOfType([propTypes.object, propTypes.string]),
    typeCulture: propTypes.oneOfType([propTypes.object, propTypes.string]),
    surfaceMP: propTypes.number,
    surface: propTypes.oneOfType([propTypes.number, propTypes.string]),
  }).isRequired,
  doubleRow: propTypes.bool,
  culturesOptions: propTypes.array.isRequired,
  categoriesCultures: propTypes.array.isRequired,
  typeCulturesFiltered: propTypes.array.isRequired,
  hasExploitationTierce: propTypes.bool,
  showAffectationColumn: propTypes.bool,
  handleCultureChange: propTypes.func,
  handleCategorieChange: propTypes.func,
  handleTypeChange: propTypes.func,
  moveParcelleToAssolement: propTypes.func,
  onKeyDown: propTypes.func,
  onChange: propTypes.func,
  deleteLine: propTypes.func,
};
AssolementRow.defaultProps = {
  onKeyDown: () => {},
  doubleRow: false,
};
export default compose(withTranslation(), connect(mapStateToProps), withRouter)(AssolementRow);
