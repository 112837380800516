import React from "react";
import { withRouter } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import network from "../../../actions/external/network";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import AssolementRow from "./AssolementRow";
import { compose } from "redux";
import DragAndDrop from "./DragAndDrop";
import { Alert, AlertTitle } from "@material-ui/lab";
import { round } from "../../../scripts/utils";

const mapStateToProps = (state) => ({
  idUtilisateur: state.auth.idUtilisateur,
  idExploitation: state.exploitation.selected.idExploitation,
  idMillesime: state.millesime.selected.idMillesime,
  app: state.app,
});

class ModifAssolement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAffectationColumn: false,
      loading: true,
      categoriesCultures: [],
      typesCultures: [],
      cultures: [],
      partner: null,
      exploitationTierce: null,
      rows: [],
      parcellesIsolees: [],
      valid: true,
      idxLigneEnDouble: -1,
      assolement: null,
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = async () => {
    const { app } = this.props;
    const isOADPAC = app.urlOAD === window.location.origin;
    this.setState({ loading: true });
    if (!isOADPAC) {
      const [
        categoriesCultures,
        typesCultures,
        cultures,
        partners,
        exploitationsTierces,
        { assolement, parcellesIsolees },
      ] = await Promise.all([
        network.fetch("/api/categories-cultures"),
        network.fetch("/api/types-cultures"),
        network.fetch("/api/cultures"),
        network.fetch(`/api/applications-tierces`),
        network.fetch(`/api/exploitations/${this.props.idExploitation}/applications-tierces-exploitations`),
        network.fetch(`/api/exploitations/${this.props.idExploitation}/assolement?millesime=${this.props.idMillesime}`),
      ]);

      this.setState({
        categoriesCultures,
        typesCultures,
        cultures,
        exploitationTierce: exploitationsTierces[0],
        partner: partners.find(
          (partner) =>
            exploitationsTierces[0] != null &&
            exploitationsTierces[0].idApplicationTierce === partner.idApplicationTierce
        ),
        rows: [...assolement.map((a) => this.parseAssolement(a, categoriesCultures, typesCultures, cultures))],
        parcellesIsolees,
        loading: false,
        assolement: {
          parcelles: parcellesIsolees,
        },
      });
    } else {
      const [
        cultures,
        typesCulturesPac,
        partners,
        exploitationsTierces,
        { assolement, parcellesIsolees },
      ] = await Promise.all([
        network.fetch("/api/cultures"),
        network.fetch("/api/cultures/pac"),
        network.fetch(`/api/applications-tierces`),
        network.fetch(`/api/exploitations/${this.props.idExploitation}/applications-tierces-exploitations`),
        network.fetch(`/api/exploitations/${this.props.idExploitation}/assolement?millesime=${this.props.idMillesime}`),
      ]);

      const precisions = [];
      typesCulturesPac.forEach(
        (typeCulturePac) =>
          (precisions[typeCulturePac.code] = [
            ...(precisions[typeCulturePac.code] !== undefined ? precisions[typeCulturePac.code] : []),
            { cle: typeCulturePac.cle, libelle: typeCulturePac.precision },
          ])
      );

      this.setState({
        cultures,
        precisions,
        exploitationTierce: exploitationsTierces[0],
        partner: partners.find(
          (partner) =>
            exploitationsTierces[0] != null &&
            exploitationsTierces[0].idApplicationTierce === partner.idApplicationTierce
        ),
        rows: [...assolement.map((a) => this.parseAssolement(a, null, null, cultures, precisions))],
        parcellesIsolees,
        loading: false,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.rows !== prevState.rows) {
      // compute validity of rows
      this.computeAssolementValidity();
    }
  }

  parseAssolement = (assolement, categoriesCultures, typesCultures, cultures, precisions) => {
    return {
      culture: cultures?.find((c) => c.idCulture === assolement.idCulture),
      typeCulture: typesCultures?.find((t) => t.idTypeCulture === assolement.idTypeCulture),
      categorieCulture: categoriesCultures?.find((c) => c.idCategorieCulture === assolement.idCategorieCulture),
      surface: assolement.surface,
      parcelles: assolement.parcelles,
      prodSemence: assolement.prodSemence,
      caractDestination: assolement.caractDestination?.toUpperCase(),
      precision: precisions && precisions[assolement.codePac.substring(0, 3)].find((p) => p.cle === assolement.codePac),
    };
  };

  handleKeyPress = (event, elemIndex) => {
    if (event.key === "Tab" && !event.shiftKey && !event.ctrlKey && elemIndex === this.state.rows.length - 1) {
      this.addLine();
    }
  };

  addLine = () =>
    this.setState((state) => {
      const rows = [...state.rows];
      if (state.rows.length === 0 || state.rows[state.rows.length - 1].culture) {
        rows.push({
          culture: null,
          categorieCulture: "",
          typeCulture: "",
          surface: 0,
          parcelles: [],
          prodSemence: false,
          caractDestination: "",
        });
      }
      return {
        rows,
      };
    });

  deleteLine = (index) => {
    this.setState((state) => ({
      rows: [...state.rows.slice(0, index), ...state.rows.slice(index + 1)],
      parcellesIsolees: [...state.parcellesIsolees, ...state.rows[index].parcelles],
    }));
  };

  handleSurfaceChange = (value, i) => {
    this.setState((prevState) => ({
      rows: [
        ...prevState.rows.slice(0, i),
        {
          ...prevState.rows[i],
          surface: Number(value),
        },
        ...prevState.rows.slice(i + 1),
      ],
    }));
  };

  handleCategorieChange = (value, i) => {
    this.setState((prevState) => ({
      rows: [
        ...prevState.rows.slice(0, i),
        {
          ...prevState.rows[i],
          categorieCulture: value,
          typeCulture: "",
        },
        ...prevState.rows.slice(i + 1),
      ],
    }));
  };

  handleCultureChange = ({ event, value, reason }, i) => {
    const { app } = this.props;
    const isOADPAC = app.urlOAD === window.location.origin;

    isOADPAC
      ? this.setState((prevState) => ({
          rows: [
            ...prevState.rows.slice(0, i),
            {
              ...prevState.rows[i],
              culture: value,
              precision: null,
            },
            ...prevState.rows.slice(i + 1),
          ],
        }))
      : this.setState((prevState) => ({
          rows: [
            ...prevState.rows.slice(0, i),
            {
              ...prevState.rows[i],
              culture: value,
              categorieCulture: value?.idCategorieCultureDefaut
                ? this.state.categoriesCultures.find((c) => c.idCategorieCulture === value.idCategorieCultureDefaut)
                : "",
              typeCulture: value?.idTypeCultureDefaut
                ? this.state.typesCultures.find((t) => t.idTypeCulture === value.idTypeCultureDefaut)
                : "",
            },
            ...prevState.rows.slice(i + 1),
          ],
        }));
  };

  handleTypeChange = (value, i) => {
    this.setState((prevState) => ({
      rows: [
        ...prevState.rows.slice(0, i),
        {
          ...prevState.rows[i],
          typeCulture: value,
        },
        ...prevState.rows.slice(i + 1),
      ],
    }));
  };

  handlePrecisionChange = (value, i) => {
    this.setState((prevState) => ({
      rows: [
        ...prevState.rows.slice(0, i),
        {
          ...prevState.rows[i],
          precision: value,
        },
        ...prevState.rows.slice(i + 1),
      ],
    }));
  };

  moveParcelleToAssolement = (uuidParcelle, i = undefined) => {
    let parcelle;
    const assolementOriginIndex = this.state.rows.findIndex((assolement) => {
      parcelle = assolement.parcelles.find((searchParcelle) => searchParcelle.uuidParcelle === uuidParcelle);
      return parcelle !== undefined;
    });

    let rows = this.state.rows,
      parcellesIsolees = this.state.parcellesIsolees;

    if (assolementOriginIndex === -1) {
      //Ligne d'assolement non trouvée == parcelles prise depuis les parcelles isolées
      const parcelleIsoleeIndex = parcellesIsolees.findIndex(
        (searchParcelle) => searchParcelle.uuidParcelle === uuidParcelle
      );
      parcelle = parcellesIsolees[parcelleIsoleeIndex];
      parcellesIsolees = [...parcellesIsolees.filter((p) => p.uuidParcelle !== uuidParcelle)];
    } else {
      //effacement de l'élément
      rows = [
        ...this.state.rows.slice(0, assolementOriginIndex),
        {
          ...this.state.rows[assolementOriginIndex],
          parcelles: this.state.rows[assolementOriginIndex].parcelles.filter((p) => p.uuidParcelle !== uuidParcelle),
        },
        ...this.state.rows.slice(assolementOriginIndex + 1),
      ];
    }

    if (i !== undefined) {
      //on insère la parcelle isolée dans les lignes d'assolement
      // insertion dans la bonne ligne
      // noinspection JSUnusedAssignment
      rows = [
        ...rows.slice(0, i),
        {
          ...rows[i],
          parcelles: [...rows[i].parcelles, parcelle],
        },
        ...rows.slice(i + 1),
      ];
    } else {
      //on insère la parcelle dans les parcelles isolées
      parcellesIsolees = [...parcellesIsolees, parcelle];
    }

    this.setState({ rows, parcellesIsolees });
  };

  computeAssolementValidity = () => {
    const { app } = this.props;
    const isOADPAC = app.urlOAD === window.location.origin;
    const rowsReversed = this.state.rows.slice().reverse(),
      indexLigneReversed = rowsReversed.findIndex((row, i) => {
        return rowsReversed.some((r, j) => {
          return (
            i !== j &&
            r?.culture?.idCulture === row?.culture?.idCulture &&
            r?.categorieCulture?.idCategorieCulture === row?.categorieCulture?.idCategorieCulture &&
            r?.typeCulture?.idTypeCulture === row?.typeCulture?.idTypeCulture
          );
        });
      }),
      indexLigneEnDouble = indexLigneReversed === -1 ? -1 : this.state.rows.length - 1 - indexLigneReversed;

    isOADPAC
      ? this.setState({
          idxLigneEnDouble: indexLigneEnDouble,
          valid: this.state.rows.every(
            (row) => (row.culture?.idCulture || row.culture?.idTypeCulture) && row.surface > 0
          ),
        })
      : this.setState({
          idxLigneEnDouble: indexLigneEnDouble,
          valid:
            indexLigneEnDouble === -1 &&
            this.state.rows.every(
              (row) =>
                row.culture?.idCulture &&
                row.categorieCulture?.idCategorieCulture &&
                row.typeCulture?.idTypeCulture &&
                row.surface > 0
            ),
        });
  };

  saveAssolement = async () => {
    const { t } = this.props;
    try {
      await network.fetch(
        `/api/exploitations/${this.props.idExploitation}/assolement?millesime=${this.props.idMillesime}`,
        {
          method: "PUT",
          body: JSON.stringify(
            this.state.rows
              .filter((r) => !!r.culture)
              .map((r) => ({
                idExploitation: this.props.idExploitation,
                idMillesime: this.props.idMillesime,
                idCulture: r.culture?.idCulture,
                idCategorieCulture: r.categorieCulture?.idCategorieCulture,
                idTypeCulture: r.typeCulture?.idTypeCulture,
                surface: r.surface,
                parcelles: r.parcelles,
                codePac: r.precision?.cle,
              }))
          ),
        }
      );
      toastr.success(
        t("saved", "Enregistré"),
        t("modif-assolement.save-success", `L'assolement de l'exploitation a été enregistré.`)
      );
      this.props.history.push("/");
    } catch (error) {
      toastr.error("Erreur", error.toString());
    }
  };

  showAffectationColumn = () => {
    this.setState({
      showAffectationColumn: !this.state.showAffectationColumn,
    });
  };

  hasExploitationTierce = () => {
    return this.state.exploitationTierce != null;
  };

  synchronizeExploitation = async () => {
    const { t, idExploitation, idMillesime } = this.props;
    const { exploitationTierce } = this.state;
    try {
      await network.fetch(
        `/api/exploitations/${idExploitation}/applications-tierces/${exploitationTierce.idApplicationTierce}?millesime=${idMillesime}`,
        {
          method: "POST",
        }
      );
      await this.loadData();
      toastr.success("OK", t("modif-assolement.refresh-data-success", "Synchronisation des données effectuée"));
    } catch (error) {
      toastr.error(
        t("error", "Erreur"),
        t("modif-assolement.refresh-data-error", "Erreur lors de la synchronisation des données")
      );
    }
  };

  render() {
    const { t, app } = this.props,
      {
        partner,
        loading,
        rows,
        parcellesIsolees,
        cultures,
        precisions,
        categoriesCultures,
        typesCultures,
        idxLigneEnDouble,
        valid,
      } = this.state,
      assolementCultures = cultures.filter((c) => c.estSelectionnable);

    const isOADPAC = app.urlOAD === window.location.origin;

    return (
      <React.Fragment>
        <Typography variant="h1" className="mb-2">
          {t("modif-assolement.title", "Modifier l'assolement")}
        </Typography>
        {loading ? (
          <div className="flex-fill d-flex justify-center align-items-center">
            <CircularProgress color="primary" size={150} />
          </div>
        ) : (
          <Card>
            <CardHeader
              title={
                <React.Fragment>
                  <Box className="d-flex">
                    <Typography variant="h3">
                      {t("modif-assolement.card-assolement-exploitation", "Données assolement exploitation")}
                    </Typography>
                    {this.hasExploitationTierce() && (
                      <Box className="d-flex justify-between flex-fill">
                        <Button className="ml-1" color="primary" variant="text" onClick={this.synchronizeExploitation}>
                          <FontAwesomeIcon icon="sync-alt" />
                          <span className="ml-1">
                            {t("modif-assolement.refresh-data", "Synchroniser toutes les données à partir de ") +
                              partner?.libelle}
                          </span>
                        </Button>
                        {!this.state.showAffectationColumn ? (
                          <Button
                            color="primary"
                            variant="contained"
                            className="mr-1"
                            onClick={this.showAffectationColumn}
                          >
                            {t("modif-assolement.affectation", "Gérer l'affectation des parcelles")}
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            variant="contained"
                            className="mr-1"
                            onClick={this.showAffectationColumn}
                          >
                            {t("modif-assolement.affectation-finish", "Terminer")}
                          </Button>
                        )}
                      </Box>
                    )}
                  </Box>
                  <span>
                    Note importante : les données issues de MesParcelles sont les surfaces mesurées des parcelles, si
                    besoin, vous avez la possibilité de modifier les données liées aux surfaces directement dans cet
                    écran
                  </span>
                </React.Fragment>
              }
            />
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ textAlign: "center" }}>
                      {t("modif-assolement.table-headers.field", "Culture")}
                    </TableCell>
                    {isOADPAC ? (
                      <React.Fragment>
                        <TableCell style={{ textAlign: "center" }}>{"Précision"}</TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {t("modif-assolement.table-headers.production-semence", "Production de semence")}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {t(
                            "modif-assolement.table-headers.caracteristique-distribution",
                            "Caracteristique de distribution"
                          )}
                        </TableCell>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <TableCell style={{ textAlign: "center" }}>
                          {t("modif-assolement.table-headers.field-category", "Catégorie")}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {t("modif-assolement.table-headers.field-type", "Type HVE")}
                        </TableCell>
                      </React.Fragment>
                    )}

                    {this.hasExploitationTierce() && (
                      <React.Fragment>
                        {this.state.showAffectationColumn ? (
                          <TableCell style={{ textAlign: "center" }}>
                            {t("modif-assolement.table-headers.affectation", "Parcelles à répartir")}
                          </TableCell>
                        ) : (
                          <TableCell style={{ maxWidth: "8rem", textAlign: "center" }}>
                            {t("modif-assolement.table-headers.parcelles-partner", "Nombre de parcelles")}
                          </TableCell>
                        )}

                        <TableCell style={{ maxWidth: "8rem", textAlign: "center" }}>
                          {t("modif-assolement.table-headers.surface-partner", "Surface MesParcelles (ha)")}
                        </TableCell>
                      </React.Fragment>
                    )}
                    <TableCell
                      style={
                        isOADPAC ? { width: "15rem", textAlign: "center" } : { maxWidth: "8rem", textAlign: "center" }
                      }
                    >
                      {isOADPAC
                        ? t("assolement.surface-admissible", "Surface admissible (ha)")
                        : t("modif-assolement.table-headers.surface-input", "Surface saisie (ha)")}
                    </TableCell>
                    <TableCell style={{ width: "1rem", textAlign: "center" }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, i) => {
                    const typeCulturesFiltered = typesCultures.filter(
                      (t) => t.idCategorieCulture === row.categorieCulture?.idCategorieCulture
                    );
                    return (
                      <AssolementRow
                        showAffectationColumn={this.state.showAffectationColumn}
                        key={i}
                        assolement={row}
                        hasExploitationTierce={this.hasExploitationTierce()}
                        doubleRow={idxLigneEnDouble === i}
                        categoriesCultures={categoriesCultures}
                        typeCulturesFiltered={typeCulturesFiltered}
                        culturesOptions={assolementCultures}
                        precisionsOptions={precisions}
                        handleCultureChange={(event, value, reason) =>
                          this.handleCultureChange(
                            {
                              event,
                              value,
                              reason,
                            },
                            i
                          )
                        }
                        handleCategorieChange={(event) => this.handleCategorieChange(event.target.value, i)}
                        handlePrecisionChange={(event) => this.handlePrecisionChange(event.target.value, i)}
                        handleTypeChange={(event) => this.handleTypeChange(event.target.value, i)}
                        moveParcelleToAssolement={(uuidParcelle) => this.moveParcelleToAssolement(uuidParcelle, i)}
                        onKeyDown={(event) => this.handleKeyPress(event, i)}
                        onChange={(value) => this.handleSurfaceChange(value, i)}
                        deleteLine={() => this.deleteLine(i)}
                      />
                    );
                  })}

                  {this.hasExploitationTierce() && (
                    <TableRow>
                      <TableCell>Parcelles isolées</TableCell>
                      <TableCell />
                      <TableCell />
                      {this.state.showAffectationColumn ? (
                        <TableCell>
                          <DragAndDrop
                            assolement={this.state.assolement}
                            moveParcelleToAssolement={(uuidParcelle) => this.moveParcelleToAssolement(uuidParcelle)}
                          />
                        </TableCell>
                      ) : (
                        <TableCell style={{ width: "7rem" }}>
                          <TextField
                            inputProps={{ style: { textAlign: "right" } }}
                            value={parcellesIsolees.length > 0 ? parcellesIsolees.length : 0}
                            variant="outlined"
                            disabled
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell colSpan={isOADPAC ? (this.hasExploitationTierce() ? 5 : 3) : 1}>
                      <Button color="primary" onClick={this.addLine}>
                        <FontAwesomeIcon icon={"plus"} className={"mr-1"} />
                        <span>Ajouter une culture</span>
                      </Button>
                    </TableCell>
                    {!isOADPAC && (
                      <React.Fragment>
                        <TableCell align="right" colSpan={this.hasExploitationTierce() ? 1 : 2} />
                        {this.hasExploitationTierce() && (
                          <React.Fragment>
                            <TableCell style={{ textAlign: "right" }}>
                              <b>{t("modif-assolement.table-total", "Total")}</b>
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                              <b>
                                {round(
                                  rows.reduce((previous, current) => previous + current.parcelles.length, 0),
                                  4
                                )}
                              </b>
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                              <b>
                                {round(
                                  rows.reduce(
                                    (previous, current) =>
                                      previous +
                                      current.parcelles
                                        .map((p) => p.surface)
                                        .reduce((acc, surface) => acc + surface, 0),
                                    0
                                  ),
                                  4
                                )}
                              </b>
                            </TableCell>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}

                    <TableCell style={{ textAlign: "right" }}>
                      <b style={{ marginLeft: "0.5rem" }}>
                        {round(
                          rows.reduce((previous, current) => previous + current.surface, 0),
                          4
                        ) + " ha"}
                      </b>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableBody>
              </Table>
              <div className="mt-2 d-flex justify-center align-items-center">
                {app.urlOAD === window.location.origin ? (
                  <Tooltip title={"L'application est en lecture seule"}>
                    <div>
                      <Button
                        color="primary"
                        variant="contained"
                        className="mr-1"
                        onClick={this.saveAssolement}
                        disabled
                      >
                        {t("modif-assolement.apply", "Valider")}
                      </Button>
                    </div>
                  </Tooltip>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-1"
                    onClick={this.saveAssolement}
                    disabled={!valid}
                  >
                    {t("modif-assolement.apply", "Valider")}
                  </Button>
                )}
                <Button onClick={() => this.props.history.goBack()}>{t("modif-assolement.cancel", "Annuler")}</Button>
              </div>
              <div>
                <Typography variant={"text"} style={{ fontStyle: "italic" }}>
                  * Cultures mineures
                </Typography>
              </div>
              {!valid && !isOADPAC && (
                <Alert severity="error" className="mt-1">
                  <AlertTitle>
                    <strong>{t("form-missing-data.title", "Impossible de valider")}</strong>
                  </AlertTitle>
                  {t(
                    "form-missing-data.message",
                    "Certaines données saisies dans le formulaire sont manquantes ou erronées."
                  )}
                </Alert>
              )}
            </CardContent>
          </Card>
        )}
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps), withRouter)(ModifAssolement);
