import React from "react";
import Indicator from "../../../Indicator";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { round } from "../../../../../../../scripts/utils";
import AssolementOadPacRow from "./AssolementOadPacRow";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state) => ({
  idUtilisateur: state.auth.idUtilisateur,
  idExploitation: state.exploitation.selected.idExploitation,
  idMillesime: state.millesime.selected.idMillesime,
});

class AssolementOadPac extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      valid: true,
      idxLigneEnDouble: -1,
    };
  }

  componentDidMount() {
    const { indicateur, assolement, precisions } = this.props;

    const indicateurSortByLibellePac = indicateur.valeur.value.sort((a, b) => {
      return a.libelleCulture?.localeCompare(b.libelleCulture, "fr", { sensitivity: "base" });
    });

    const assolementSortByLibellePac = assolement.sort((a, b) => {
      return a.libelleCulturePac?.localeCompare(b.libelleCulturePac, "fr", { sensitivity: "base" });
    });

    if (!indicateur.valeur.touched) {
      this.saveValeurIndicateur({
        touched: true,
        value: assolementSortByLibellePac
          .filter((a) => a.codePac != null)
          .map((a) => ({
            ...this.getNewLine(),
            idCulture: a.idTypeCulturePac,
            libelleCulture: a.libelleCulturePac,
            code: a.codePac.substring(0, 3),
            precision: precisions[a.codePac.substring(0, 3)]?.find((precision) => precision.cle === a.codePac),
            sfpPlaineTotalite: a?.sfpPlaineTotalite,
            sfpPlainePartiel: a?.sfpPlainePartiel,
            sfpIchnTotalite: a?.sfpIchnTotalite,
            sfpCerealesAutoconsommes: a?.sfpCerealesAutoconsommes,
            surfaceAdmissible: a.surface,
            productionSemence: a.productionSemence ? a.productionSemence : false,
            fourrage: a.caractDestination ? a.caractDestination.toUpperCase() : "",
            interRangs: a.interRangs,
            isLabouree: a.isLabouree,
          })),
      });
    } else {
      this.saveValeurIndicateur({
        value: indicateurSortByLibellePac
          .filter((item) => item.code != null)
          .map((item) => ({
            ...this.getNewLine(),
            idCulture: item.idCulture,
            libelleCulture: item.libelleCulture,
            code: item.code,
            precision: item.precision,
            sfpPlaineTotalite: item?.sfpPlaineTotalite,
            sfpPlainePartiel: item?.sfpPlainePartiel,
            sfpIchnTotalite: item?.sfpIchnTotalite,
            sfpCerealesAutoconsommes: item?.sfpCerealesAutoconsommes,
            surfaceAdmissible: item.surfaceAdmissible,
            productionSemence: item.productionSemence ? item.productionSemence : false,
            fourrage: item.fourrage ? item.fourrage.toUpperCase() : "",
            interRangs: item.interRangs,
            isLabouree: item.isLabouree,
          })),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.indicateur.valeur.value !== prevProps.indicateur.valeur.value) {
      // compute validity of rows
      this.computeAssolementValidity();
    }
  }

  handleKeyPress = (event, elemIndex) => {
    if (
      event.key === "Tab" &&
      !event.shiftKey &&
      !event.ctrlKey &&
      elemIndex === this.props.indicateur.valeur.value.length - 1
    ) {
      this.addLine();
    }
  };

  getNewLine = () => ({
    idCulture: null,
    code: null,
    productionSemence: false,
    isLabouree: "",
    interRangs: "",
    fourrage: "",
    surfaceAdmissible: 0,
    libelleCulture: "",
  });

  saveValeurIndicateur = (valeur) => {
    const { indicateur, sousRubriqueIndex, libelleEnglobantIndex, changeIndicateur } = this.props;
    changeIndicateur(
      {
        ...indicateur,
        valeur: {
          ...indicateur.valeur,
          ...valeur,
        },
      },
      sousRubriqueIndex,
      libelleEnglobantIndex
    );
  };

  addLine = () => {
    const rows = this.props.indicateur.valeur.value;
    if (rows.length === 0 || rows[rows.length - 1].idCulture != null) {
      this.saveValeurIndicateur({
        value: [...rows, this.getNewLine()],
      });
    }
  };

  deleteLine = (index) => {
    const rows = this.props.indicateur.valeur.value;
    this.saveValeurIndicateur({
      value: [...rows.slice(0, index), ...rows.slice(index + 1)],
    });
  };

  handleInput = (value, i, name) => {
    const rows = this.props.indicateur.valeur.value;
    this.saveValeurIndicateur({
      value: [
        ...rows.slice(0, i),
        {
          ...rows[i],
          [name]: value,
          touched: true,
        },
        ...rows.slice(i + 1),
      ],
    });
  };

  handleCultureChange = (value, i, name) => {
    const rows = this.props.indicateur.valeur.value;
    this.saveValeurIndicateur({
      value: [
        ...rows.slice(0, i),
        {
          ...rows[i],
          [name]: value?.idTypeCulture,
          code: value?.code,
          sfpPlaineTotalite: value?.sfpPlaineTotalite,
          sfpPlainePartiel: value?.sfpPlainePartiel,
          sfpIchnTotalite: value?.sfpIchnTotalite,
          sfpCerealesAutoconsommes: value?.sfpCerealesAutoconsommes,
          touched: true,
          libelleCulture: value?.libelle,
        },
        ...rows.slice(i + 1),
      ],
    });
  };

  handlePrecisionChange = (value, i) => {
    const { precisions } = this.props;
    const rows = this.props.indicateur.valeur.value;

    this.saveValeurIndicateur({
      value: [
        ...rows.slice(0, i),
        {
          ...rows[i],
          precision: precisions[rows[i].code].find((precision) => precision.cle === value),
        },
        ...rows.slice(i + 1),
      ],
    });
  };

  computeAssolementValidity = () => {
    const rows = this.props.indicateur.valeur.value;
    const rowsReversed = rows.slice().reverse();
    const indexLigneReversed = rowsReversed.findIndex((row, i) => {
      return rowsReversed.some((r, j) => {
        return i !== j && r.idCulture === row.idCulture;
      });
    });
    const indexLigneEnDouble = indexLigneReversed === -1 ? -1 : rows.length - 1 - indexLigneReversed;
    this.setState({
      idxLigneEnDouble: indexLigneEnDouble,
      valid: indexLigneEnDouble === -1 && rows.every((row) => row.idCulture != null),
    });
  };

  getValueRendered = () => {
    const { t, indicateur, culturesPac, precisions } = this.props;
    const { idxLigneEnDouble } = this.state;
    let reducedCulturesPac = [];
    culturesPac.map(
      (culture) => !reducedCulturesPac.find((c) => c.code === culture.code) && reducedCulturesPac.push(culture)
    );

    return (
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: "center" }}>{t("oad-pac-assolement.culture", "Culture")}</TableCell>
              <TableCell style={{ textAlign: "center" }}>{"Precision"}</TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {t("oad-pac-assolement.production-semence", "Surfaces sont en production de semences (oui/non)")}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {t(
                  "oad-pac-assolement.labouree",
                  " Si prairie permanente, indiquer si celle-ci est labourée ou non durant l'année civile"
                )}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {t(
                  "oad-pac-assolement.inter-rangs",
                  " Si vigne ou verger, indiquer les inter-rangs avec une couverture végétale"
                )}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {t("oad-pac-assolement.fourrage", "Si fourrage, indiquer si commercialisé ou autoconsommé")}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {t("oad-pac-assolement.surface-admissible", "Surface admissible cultivée (ha)")}
              </TableCell>
              <TableCell
                style={{
                  width: "1rem",
                  textAlign: "center",
                }}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {indicateur.valeur.value.map((row, i) => {
              return (
                <AssolementOadPacRow
                  key={i}
                  assolement={row}
                  culture={culturesPac.find((c) => c.code === row.code)}
                  doubleRow={idxLigneEnDouble === i}
                  culturesOptions={reducedCulturesPac}
                  precisionsOptions={precisions[row.code]}
                  handleCultureChange={(event, value, reason) => this.handleCultureChange(value, i, "idCulture")}
                  handlePrecisionChange={(event) => this.handlePrecisionChange(event.target.value, i)}
                  handleListeInterRang={(event) => this.handleInput(event.target.value, i, "interRangs")}
                  handleListeFourrage={(event) => this.handleInput(event.target.value, i, "fourrage")}
                  handleProductionSemence={(event) => this.handleInput(event.target.checked, i, "productionSemence")}
                  handleIsLabouree={(event) => this.handleInput(event.target.value, i, "isLabouree")}
                  handleSurfaceAdmissibleChange={(value) => this.handleInput(Number(value), i, "surfaceAdmissible")}
                  onKeyDown={(event) => this.handleKeyPress(event, i)}
                  deleteLine={() => this.deleteLine(i)}
                />
              );
            })}
            <TableRow>
              <TableCell colSpan={2}>
                <Button color="primary" onClick={this.addLine}>
                  <FontAwesomeIcon icon={"plus"} className={"mr-1"} />
                  <span>Ajouter une culture</span>
                </Button>
              </TableCell>
              <TableCell align="right" colSpan={3} />
              <TableCell style={{ textAlign: "right" }}>
                <b style={{ marginLeft: "0.5rem" }}>
                  {round(
                    indicateur.valeur.value.reduce((previous, current) => previous + current.surfaceAdmissible, 0),
                    2
                  ) + " ha"}
                </b>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    );
  };

  render() {
    const { indicateur, onChangeIndicator, demarche } = this.props;

    return (
      <Indicator
        indicateur={indicateur}
        onChange={onChangeIndicator}
        alignScoreToTheEnd
        demarche={demarche}
        showCommentaire={false}
      >
        {this.getValueRendered()}
      </Indicator>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps), withRouter)(AssolementOadPac);
