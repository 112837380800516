function resetValueAssocie1(state, num) {
  if (state.indicateurs.OADPAC_D_A03.valeur.value !== "GAEC" || state.indicateurs.OADPAC_D_2.valeur.value < num) {
    return {
      valeur: {
        value: {
          isActif: false,
          partsSociales: 0,
        },
      },
    };
  }
}

const resetValuePartPac = (state) => {
  if (state.indicateurs.OADPAC_D_A03.valeur.value !== "GAEC") {
    return {
      valeur: {
        value: null,
      },
    };
  }
};

const dpt_zone = [
  {
    dpt: "01",
    zone: "INCERTAIN",
  },
  {
    dpt: "02",
    zone: "PLAINE",
  },
  {
    dpt: "03",
    zone: "INCERTAIN",
  },
  {
    dpt: "04",
    zone: "INCERTAIN",
  },
  {
    dpt: "05",
    zone: "MONTAGNE",
  },
  {
    dpt: "06",
    zone: "INCERTAIN",
  },
  {
    dpt: "07",
    zone: "INCERTAIN",
  },
  {
    dpt: "08",
    zone: "PLAINE",
  },
  {
    dpt: "09",
    zone: "INCERTAIN",
  },
  {
    dpt: "10",
    zone: "PLAINE",
  },
  {
    dpt: "11",
    zone: "INCERTAIN",
  },
  {
    dpt: "12",
    zone: "INCERTAIN",
  },
  {
    dpt: "13",
    zone: "PLAINE",
  },
  {
    dpt: "14",
    zone: "PLAINE",
  },
  {
    dpt: "15",
    zone: "MONTAGNE",
  },
  {
    dpt: "16",
    zone: "PLAINE",
  },
  {
    dpt: "17",
    zone: "PLAINE",
  },
  {
    dpt: "18",
    zone: "PLAINE",
  },
  {
    dpt: "19",
    zone: "INCERTAIN",
  },
  {
    dpt: "20",
    zone: "INCERTAIN",
  },
  {
    dpt: "21",
    zone: "INCERTAIN",
  },
  {
    dpt: "22",
    zone: "PLAINE",
  },
  {
    dpt: "23",
    zone: "INCERTAIN",
  },
  {
    dpt: "24",
    zone: "PLAINE",
  },
  {
    dpt: "25",
    zone: "INCERTAIN",
  },
  {
    dpt: "26",
    zone: "INCERTAIN",
  },
  {
    dpt: "27",
    zone: "PLAINE",
  },
  {
    dpt: "28",
    zone: "PLAINE",
  },
  {
    dpt: "29",
    zone: "PLAINE",
  },
  {
    dpt: "30",
    zone: "INCERTAIN",
  },
  {
    dpt: "31",
    zone: "INCERTAIN",
  },
  {
    dpt: "32",
    zone: "PLAINE",
  },
  {
    dpt: "33",
    zone: "PLAINE",
  },
  {
    dpt: "34",
    zone: "INCERTAIN",
  },
  {
    dpt: "35",
    zone: "PLAINE",
  },
  {
    dpt: "36",
    zone: "PLAINE",
  },
  {
    dpt: "37",
    zone: "PLAINE",
  },
  {
    dpt: "38",
    zone: "INCERTAIN",
  },
  {
    dpt: "39",
    zone: "INCERTAIN",
  },
  {
    dpt: "40",
    zone: "PLAINE",
  },
  {
    dpt: "41",
    zone: "PLAINE",
  },
  {
    dpt: "42",
    zone: "PLAINE",
  },
  {
    dpt: "43",
    zone: "PLAINE",
  },
  {
    dpt: "44",
    zone: "PLAINE",
  },
  {
    dpt: "45",
    zone: "PLAINE",
  },
  {
    dpt: "46",
    zone: "INCERTAIN",
  },
  {
    dpt: "47",
    zone: "PLAINE",
  },

  {
    dpt: "48",
    zone: "MONTAGNE",
  },
  {
    dpt: "49",
    zone: "PLAINE",
  },
  {
    dpt: "50",
    zone: "PLAINE",
  },
  {
    dpt: "51",
    zone: "PLAINE",
  },
  {
    dpt: "52",
    zone: "PLAINE",
  },
  {
    dpt: "53",
    zone: "PLAINE",
  },
  {
    dpt: "54",
    zone: "INCERTAIN",
  },
  {
    dpt: "55",
    zone: "PLAINE",
  },
  {
    dpt: "56",
    zone: "PLAINE",
  },
  {
    dpt: "57",
    zone: "INCERTAIN",
  },
  {
    dpt: "58",
    zone: "INCERTAIN",
  },
  {
    dpt: "59",
    zone: "PLAINE",
  },
  {
    dpt: "60",
    zone: "PLAINE",
  },
  {
    dpt: "61",
    zone: "PLAINE",
  },
  {
    dpt: "62",
    zone: "PLAINE",
  },
  {
    dpt: "63",
    zone: "INCERTAIN",
  },
  {
    dpt: "64",
    zone: "INCERTAIN",
  },
  {
    dpt: "65",
    zone: "INCERTAIN",
  },
  {
    dpt: "66",
    zone: "INCERTAIN",
  },
  {
    dpt: "67",
    zone: "INCERTAIN",
  },
  {
    dpt: "68",
    zone: "INCERTAIN",
  },
  {
    dpt: "69",
    zone: "INCERTAIN",
  },
  {
    dpt: "70",
    zone: "INCERTAIN",
  },
  {
    dpt: "71",
    zone: "INCERTAIN",
  },
  {
    dpt: "72",
    zone: "PLAINE",
  },
  {
    dpt: "73",
    zone: "INCERTAIN",
  },
  {
    dpt: "74",
    zone: "INCERTAIN",
  },
  {
    dpt: "75",
    zone: "PLAINE",
  },
  {
    dpt: "76",
    zone: "PLAINE",
  },
  {
    dpt: "77",
    zone: "PLAINE",
  },
  {
    dpt: "78",
    zone: "PLAINE",
  },
  {
    dpt: "79",
    zone: "PLAINE",
  },
  {
    dpt: "80",
    zone: "PLAINE",
  },
  {
    dpt: "81",
    zone: "INCERTAIN",
  },
  {
    dpt: "82",
    zone: "PLAINE",
  },
  {
    dpt: "83",
    zone: "INCERTAIN",
  },
  {
    dpt: "84",
    zone: "INCERTAIN",
  },
  {
    dpt: "85",
    zone: "PLAINE",
  },
  {
    dpt: "86",
    zone: "PLAINE",
  },
  {
    dpt: "87",
    zone: "INCERTAIN",
  },
  {
    dpt: "88",
    zone: "INCERTAIN",
  },
  {
    dpt: "89",
    zone: "INCERTAIN",
  },
  {
    dpt: "90",
    zone: "INCERTAIN",
  },
  {
    dpt: "91",
    zone: "PLAINE",
  },
  {
    dpt: "92",
    zone: "PLAINE",
  },
  {
    dpt: "93",
    zone: "PLAINE",
  },
  {
    dpt: "94",
    zone: "PLAINE",
  },
  {
    dpt: "95",
    zone: "PLAINE",
  },
  {
    dpt: "96",
    zone: "INCERTAIN",
  },
  {
    dpt: "97",
    zone: "INCERTAIN",
  },
  {
    dpt: "98",
    zone: "INCERTAIN",
  },
  {
    dpt: "99",
    zone: "INCERTAIN",
  },
  {
    dpt: "100",
    zone: "INCERTAIN",
  },
];
export const CODE_NATIONAL = "OAD_PAC";

const SFP_PLAINE_TOTALITE = [
  "BOP",
  "BRH",
  "BRO",
  "BVF",
  "CAE",
  "CAF",
  "CEE",
  "CGH",
  "CGS",
  "CHF",
  "CPL",
  "DTY",
  "FAG",
  "FET",
  "FFO",
  "FLO",
  "FSG",
  "GFP",
  "JOS",
  "LEF",
  "LFH",
  "LFP",
  "LUZ",
  "MEL",
  "MIE",
  "MLC",
  "MLF",
  "MLG",
  "NVF",
  "PAT",
  "PCL",
  "PFH",
  "PFP",
  "PPH",
  "PRL",
  "PTR",
  "RDF",
  "RGA",
  "ROS",
  "SAI",
  "SER",
  "SPH",
  "SPL",
  "TRE",
  "VES",
  "XFE",
];
const SFP_ICHN_TOTALITE = [
  "BOP",
  "BRH",
  "BRO",
  "BVF",
  "CAE",
  "CAF",
  "CEE",
  "CGH",
  "CGS",
  "CHF",
  "CPL",
  "DTY",
  "FAG",
  "FET",
  "FFO",
  "FLO",
  "FSG",
  "GFP",
  "JOS",
  "LEF",
  "LFH",
  "LFP",
  "LUZ",
  "MEL",
  "MLC",
  "MLF",
  "MLG",
  "NVF",
  "PAT",
  "PCL",
  "PFH",
  "PFP",
  "PPH",
  "PRL",
  "PTR",
  "RDF",
  "RGA",
  "ROS",
  "SAI",
  "SER",
  "SPH",
  "SPL",
  "TRE",
  "VES",
  "XFE",
];
const SFP_CEREALES_AUTOCONSOMMES = [
  "AVH",
  "AVP",
  "BDH",
  "BDP",
  "BTH",
  "BTP",
  "CAG",
  "CGF",
  "CGO",
  "CGP",
  "CHA",
  "CHH",
  "CHS",
  "CHT",
  "CPA",
  "CPH",
  "CPS",
  "CPT",
  "CPZ",
  "EPE",
  "MCR",
  "MID",
  "MIE",
  "MIS",
  "MLT",
  "MOH",
  "ORH",
  "ORP",
  "RIZ",
  "PFH",
  "SGH",
  "SGP",
  "SOG",
  "TTH",
  "TTP",
];

export const COMPUTED_FIELDS = {
  OADPAC_D_1: {
    columns: ["OADPAC_D_A03"],
    computedValue: resetValuePartPac,
  },
  OADPAC_D_4: {
    columns: ["OADPAC_D_A03", "OADPAC_D_2"],
    computedValue: (state) => {
      return resetValueAssocie1(state, 1);
    },
  },
  OADPAC_D_5: {
    columns: ["OADPAC_D_A03", "OADPAC_D_2"],
    computedValue: (state) => {
      return resetValueAssocie1(state, 2);
    },
  },
  OADPAC_D_6: {
    columns: ["OADPAC_D_A03", "OADPAC_D_2"],
    computedValue: (state) => {
      return resetValueAssocie1(state, 3);
    },
  },
  OADPAC_D_7: {
    columns: ["OADPAC_D_A03", "OADPAC_D_2"],
    computedValue: (state) => {
      return resetValueAssocie1(state, 4);
    },
  },
  OADPAC_D_8: {
    columns: ["OADPAC_D_A03", "OADPAC_D_2"],
    computedValue: (state) => {
      return resetValueAssocie1(state, 5);
    },
  },
  OADPAC_D_9: {
    columns: ["OADPAC_D_A03", "OADPAC_D_2"],
    computedValue: (state) => {
      return resetValueAssocie1(state, 6);
    },
  },
  OADPAC_D_10: {
    columns: ["OADPAC_D_A03", "OADPAC_D_2"],
    computedValue: (state) => {
      return resetValueAssocie1(state, 7);
    },
  },
  OADPAC_D_11: {
    columns: ["OADPAC_D_A03", "OADPAC_D_2"],
    computedValue: (state) => {
      return resetValueAssocie1(state, 8);
    },
  },
  OADPAC_D_12: {
    columns: ["OADPAC_D_A03", "OADPAC_D_2"],
    computedValue: (state) => {
      return resetValueAssocie1(state, 9);
    },
  },
  OADPAC_D_13: {
    columns: ["OADPAC_D_A03", "OADPAC_D_2"],
    computedValue: (state) => {
      return resetValueAssocie1(state, 10);
    },
  },
  OADPAC_D_96: {
    columns: ["OADPAC_D_39"],
    disabled: true,
    computedValue: (state) => {
      const arraySurfaceAdmissible = state.indicateurs.OADPAC_D_39.valeur.value.map((item) => item.surfaceAdmissible);
      return {
        valeur: {
          value:
            arraySurfaceAdmissible.length > 0
              ? arraySurfaceAdmissible?.reduce((previousValue, currentValue) => previousValue + currentValue)
              : 0,
        },
      };
    },
  },
  OADPAC_D_97: {
    columns: ["OADPAC_D_82", "OADPAC_D_84", "OADPAC_D_86", "OADPAC_D_88", "OADPAC_D_90"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: COMPUTED_FIELDS.OADPAC_D_97.columns.reduce(
            (sum, codeNational) => sum + getValueOrZero(state, codeNational),
            0
          ),
        },
      };
    },
  },
  OADPAC_D_98: {
    columns: ["OADPAC_D_83", "OADPAC_D_85", "OADPAC_D_87", "OADPAC_D_89", "OADPAC_D_91"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: COMPUTED_FIELDS.OADPAC_D_98.columns.reduce(
            (sum, codeNational) => sum + getValueOrZero(state, codeNational),
            0
          ),
        },
      };
    },
  },
  OADPAC_D_44: {
    columns: ["OADPAC_D_A02", "OADPAC_D_39"],
    disabled: true,
    computedValue: (state) => {
      const myDpt =
        state.exploitation.commune.codepostal != null
          ? state.exploitation.commune.codepostal.substring(0, 2).toString()
          : 0;
      const ICHN = state.indicateurs.OADPAC_D_A02.valeur.value;

      let totalSFPPlaineTotalite = state.indicateurs.OADPAC_D_39.valeur.value
        .filter((c) => SFP_PLAINE_TOTALITE.includes(c.code))
        .filter((c) => c.sfpPlaineTotalite === true)
        .map((c) => c.surfaceAdmissible)
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

      const myZone = dpt_zone.filter((item) => item.dpt === myDpt).map((item) => item.zone)[0];

      return {
        valeur: {
          value: ICHN === "PLAINE" || myZone === "PLAINE" ? totalSFPPlaineTotalite : 0,
        },
      };
    },
  },
  OADPAC_D_45: {
    columns: ["OADPAC_D_A02", "OADPAC_D_39"],
    disabled: true,
    computedValue: (state) => {
      const myDpt =
        state.exploitation.commune.codepostal != null
          ? state.exploitation.commune.codepostal.substring(0, 2).toString()
          : 0;
      const ICHN = state.indicateurs.OADPAC_D_A02.valeur.value;

      let totalSFPICHNTotalite = state.indicateurs.OADPAC_D_39.valeur.value
        .filter((c) => SFP_ICHN_TOTALITE.includes(c.code))
        .filter((c) => c.sfpIchnTotalite === true)
        .filter((c) => c.fourrage !== "COMMERCIALISATION")
        .map((c) => c.surfaceAdmissible)
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

      let totalSFPCerealesAutoConsommes = state.indicateurs.OADPAC_D_39.valeur.value
        .filter((c) => SFP_CEREALES_AUTOCONSOMMES.includes(c.code))
        .filter((c) => c.sfpCerealesAutoconsommes === true)
        .filter((c) => c.fourrage === "AUTOCONSOMMATION")
        .map((c) => c.surfaceAdmissible)
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

      const myZone = dpt_zone.filter((item) => item.dpt === myDpt).map((item) => item.zone)[0];

      return {
        valeur: {
          value: ICHN !== "PLAINE" && myZone !== "PLAINE" ? totalSFPICHNTotalite + totalSFPCerealesAutoConsommes : 0,
        },
      };
    },
  },
  OADPAC_D_46: {
    columns: [
      "OADPAC_D_A02",
      "OADPAC_D_39",
      "OADPAC_D_52",
      "OADPAC_D_53",
      "OADPAC_D_54",
      "OADPAC_D_55",
      "OADPAC_D_57",
      "OADPAC_D_58",
      "OADPAC_D_59",
      "OADPAC_D_60",
      "OADPAC_D_61",
      "OADPAC_D_62",
      "OADPAC_D_63",
      "OADPAC_D_64",
      "OADPAC_D_65",
      "OADPAC_D_66",
      "OADPAC_D_68",
      "OADPAC_D_69",
      "OADPAC_D_70",
      "OADPAC_D_71",
      "OADPAC_D_72",
      "OADPAC_D_73",
      "OADPAC_D_74",
      "OADPAC_D_75",
      "OADPAC_D_76",
      "OADPAC_D_77",
      "OADPAC_D_78",
      "OADPAC_D_79",
    ],
    disabled: true,
    computedValue: (state) => {
      const myDpt =
        state.exploitation.commune.codepostal != null
          ? state.exploitation.commune.codepostal.substring(0, 2).toString()
          : 0;
      const ICHN = state.indicateurs.OADPAC_D_A02.valeur.value;

      let totalSFPCerealesAutoConsommes = state.indicateurs.OADPAC_D_39.valeur.value
        .filter((c) => SFP_CEREALES_AUTOCONSOMMES.includes(c.code))
        .filter((c) => c.sfpCerealesAutoconsommes === true)
        .map((c) => c.surfaceAdmissible)
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

      const vaches =
        getValueOrZero(state, "OADPAC_D_52") +
        getValueOrZero(state, "OADPAC_D_53") +
        getValueOrZero(state, "OADPAC_D_54") +
        getValueOrZero(state, "OADPAC_D_55");

      const boeufs =
        getValueOrZero(state, "OADPAC_D_57") +
        getValueOrZero(state, "OADPAC_D_58") +
        getValueOrZero(state, "OADPAC_D_59") +
        getValueOrZero(state, "OADPAC_D_60") +
        getValueOrZero(state, "OADPAC_D_61") +
        getValueOrZero(state, "OADPAC_D_62") +
        getValueOrZero(state, "OADPAC_D_63") +
        getValueOrZero(state, "OADPAC_D_64");

      const veaux = getValueOrZero(state, "OADPAC_D_65") + getValueOrZero(state, "OADPAC_D_66");

      const brebis =
        getValueOrZero(state, "OADPAC_D_68") +
        getValueOrZero(state, "OADPAC_D_69") +
        getValueOrZero(state, "OADPAC_D_70") +
        getValueOrZero(state, "OADPAC_D_71");

      const chevres = getValueOrZero(state, "OADPAC_D_72");

      const equides =
        getValueOrZero(state, "OADPAC_D_73") +
        getValueOrZero(state, "OADPAC_D_74") +
        getValueOrZero(state, "OADPAC_D_75") +
        getValueOrZero(state, "OADPAC_D_76") +
        getValueOrZero(state, "OADPAC_D_77");

      const porcs = getValueOrZero(state, "OADPAC_D_78") + getValueOrZero(state, "OADPAC_D_79");
      const myZone = dpt_zone.filter((item) => item.dpt === myDpt).map((item) => item.zone)[0];

      return {
        valeur: {
          value:
            (ICHN === "MONTAGNE" || myZone === "MONTAGNE") &&
            vaches === 0 &&
            boeufs === 0 &&
            veaux === 0 &&
            brebis === 0 &&
            chevres === 0 &&
            equides === 0 &&
            porcs > 0
              ? totalSFPCerealesAutoConsommes
              : 0,
        },
      };
    },
  },
};

export const HIDDEN_FIELDS = {
  OADPAC_D_1: (state) => state.indicateurs.OADPAC_D_A03.valeur.value !== "GAEC",
  OADPAC_D_4: (state) =>
    state.indicateurs.OADPAC_D_A03.valeur.value !== "GAEC" ||
    state.indicateurs.OADPAC_D_2.valeur.value < 1 ||
    state.indicateurs.OADPAC_D_2.valeur.value == null,
  OADPAC_D_5: (state) =>
    state.indicateurs.OADPAC_D_A03.valeur.value !== "GAEC" ||
    state.indicateurs.OADPAC_D_2.valeur.value < 2 ||
    state.indicateurs.OADPAC_D_2.valeur.value == null,
  OADPAC_D_6: (state) =>
    state.indicateurs.OADPAC_D_A03.valeur.value !== "GAEC" ||
    state.indicateurs.OADPAC_D_2.valeur.value < 3 ||
    state.indicateurs.OADPAC_D_2.valeur.value == null,
  OADPAC_D_7: (state) =>
    state.indicateurs.OADPAC_D_A03.valeur.value !== "GAEC" ||
    state.indicateurs.OADPAC_D_2.valeur.value < 4 ||
    state.indicateurs.OADPAC_D_2.valeur.value == null,
  OADPAC_D_8: (state) =>
    state.indicateurs.OADPAC_D_A03.valeur.value !== "GAEC" ||
    state.indicateurs.OADPAC_D_2.valeur.value < 5 ||
    state.indicateurs.OADPAC_D_2.valeur.value == null,
  OADPAC_D_9: (state) =>
    state.indicateurs.OADPAC_D_A03.valeur.value !== "GAEC" ||
    state.indicateurs.OADPAC_D_2.valeur.value < 6 ||
    state.indicateurs.OADPAC_D_2.valeur.value == null,
  OADPAC_D_10: (state) =>
    state.indicateurs.OADPAC_D_A03.valeur.value !== "GAEC" ||
    state.indicateurs.OADPAC_D_2.valeur.value < 7 ||
    state.indicateurs.OADPAC_D_2.valeur.value == null,
  OADPAC_D_11: (state) =>
    state.indicateurs.OADPAC_D_A03.valeur.value !== "GAEC" ||
    state.indicateurs.OADPAC_D_2.valeur.value < 8 ||
    state.indicateurs.OADPAC_D_2.valeur.value == null,
  OADPAC_D_12: (state) =>
    state.indicateurs.OADPAC_D_A03.valeur.value !== "GAEC" ||
    state.indicateurs.OADPAC_D_2.valeur.value < 9 ||
    state.indicateurs.OADPAC_D_2.valeur.value == null,
  OADPAC_D_13: (state) =>
    state.indicateurs.OADPAC_D_A03.valeur.value !== "GAEC" ||
    state.indicateurs.OADPAC_D_2.valeur.value < 10 ||
    state.indicateurs.OADPAC_D_2.valeur.value == null,
};

export const HIDDEN_LIBELLE_ENGLOBANTS = {};

const getValueOrZero = (state, codeNational) =>
  state.indicateurs[codeNational] && !isNaN(Number(state.indicateurs[codeNational].valeur.value))
    ? Number(state.indicateurs[codeNational].valeur.value)
    : 0;

const exports = {
  codeNational: CODE_NATIONAL,
  computedFields: COMPUTED_FIELDS,
  hiddenFields: HIDDEN_FIELDS,
  hiddenLibelleEnglobants: HIDDEN_LIBELLE_ENGLOBANTS,
};
export default exports;
