import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, withRouter } from "react-router-dom";
import { withTheme } from "@material-ui/core/styles";
import network from "../../../actions/external/network";
import { toastr } from "react-redux-toastr";
import styles from "./Home.module.scss";
import { Skeleton } from "@material-ui/lab";
import SigaModal from "../../common/SigaModal";
import { compose } from "redux";
import Box from "@material-ui/core/Box";

const mapStateToProps = (state) => ({
  exploitation: state.exploitation.selected,
  millesime: state.millesime.selected,
  organisme: state.organisme.selected,
  app: state.app,
  auth: state.auth,
});

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showModal: false,
      modalData: {
        selected: [],
      },
      activites: {
        principal: null,
        secondaires: [],
      },
      demarches: [],
      exploitationDemarches: [],
    };
  }

  componentDidMount() {
    // load exp activities
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { app } = this.props;
    const isOADPAC = app.urlOAD === window.location.origin;
    if (isOADPAC && prevProps?.organisme?.idOrganisme !== this.props?.organisme?.idOrganisme) {
      this.props.history.push("/change-exploitation");
    }
  }

  loadData = async () => {
    const { t, exploitation, millesime, app } = this.props;
    const isOADPAC = app.urlOAD === window.location.origin;
    try {
      const [demarches, exploitationDemarches, activites] = await Promise.all([
        network.fetch(`/api/demarches?isOadPac=${isOADPAC}`),
        network.fetch(`/api/exploitations/${exploitation.idExploitation}/demarches`),
        network.fetch(
          `/api/exploitations/${exploitation.idExploitation}/activities?millesime=${millesime.idMillesime}`
        ),
      ]);
      this.setState({
        loading: false,
        activites,
        demarches,
        exploitationDemarches,
        modalData: {
          selected: exploitationDemarches,
        },
      });
    } catch (error) {
      console.error("an error occured while loading exploitation activities", error);
      toastr.error(t("error", "Erreur"), error.message);
    }
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  updateExploitationDemarches = async () => {
    const { exploitation } = this.props;
    const selectedDemarches = this.state.modalData.selected;
    await network.fetch(`/api/exploitations/${exploitation.idExploitation}/demarches`, {
      method: "PUT",
      body: JSON.stringify(selectedDemarches.map((d) => d.idDemarche)),
    });
    this.setState({ exploitationDemarches: selectedDemarches });
    this.toggleModal();
  };

  renderTitle = () => {
    const { t, theme } = this.props;
    return (
      <div className="d-flex justify-between align-items-center pb-2">
        <Typography variant="h1">{t("home.title", "Tableau de bord")}</Typography>
        <Button component={Link} to="/documents" variant="text">
          <span className={styles.linkNavigation}>{t("home.go-to-docs", "Accéder à l'espace documentaire")}</span>
          <FontAwesomeIcon color={theme.palette.primary.main} icon="arrow-right" />
        </Button>
      </div>
    );
  };

  renderSidebar = () => {
    const { t, exploitation } = this.props;
    const { activites, loading } = this.state;
    return (
      <div className="d-flex flex-column w-25">
        <Card>
          <CardHeader
            title={
              <div className="d-flex justify-between align-items-start">
                <Typography variant="h3">{t("home.data-exploitation", "Données de l'exploitation")}</Typography>
                <Tooltip title={t("edit", "Modifier")}>
                  <IconButton component={Link} to="/modif-exploitation">
                    <FontAwesomeIcon icon="pencil-alt" />
                  </IconButton>
                </Tooltip>
              </div>
            }
          />
          <CardContent>
            {loading ? (
              <React.Fragment>
                <Skeleton className="mb-1" />
                <Skeleton className="mb-1" />
                <Skeleton className="mb-1" />
                <Skeleton className="mb-1" />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <p>Siret {exploitation.siret}</p>
                <p>{exploitation.raisonSociale}</p>
                <p>
                  {exploitation.codePostal != null
                    ? exploitation.codePostal + " " + exploitation.nomCommune
                    : exploitation.nomCommune}
                </p>
                <p>{exploitation.numeroPortable}</p>
                <p>{exploitation.adresseMail}</p>
              </React.Fragment>
            )}
            <Divider className="my-2" />

            <Typography variant="h4">{t("home.activities.label", "Activités")}</Typography>

            <table>
              <tbody>
                <tr className={styles.textTop}>
                  <td>
                    <Typography style={{ fontSize: "1.2em" }} className="my-1">
                      {t("home.activities.primary", "Principale :")}
                    </Typography>
                  </td>
                  <td>
                    {activites?.principal?.map((activite) => (
                      <Typography key={activite.idActivite} style={{ paddingLeft: "1em", height: "2em" }}>
                        {activite.libelle}
                      </Typography>
                    ))}
                  </td>
                </tr>
                <tr className={styles.textTop}>
                  <td>
                    <Typography style={{ fontSize: "1.2em" }}>
                      {t("home.activities.secondary", "Secondaires :")}
                    </Typography>
                  </td>
                  <td>
                    {activites?.secondaire?.map((activite) => (
                      <Typography key={activite.idActivite} style={{ paddingLeft: "1em", height: "2em" }}>
                        {activite.libelle}
                      </Typography>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </CardContent>
        </Card>
        <Card className="d-flex justify-between align-items-center mt-2 p-2">
          <Typography variant="h3">{t("home.assolement", "Assolement")}</Typography>
          <Box>
            <Tooltip title={t("edit", "Modifier")}>
              <IconButton component={Link} to="/modif-assolement">
                <FontAwesomeIcon icon="pencil-alt" />
              </IconButton>
            </Tooltip>
          </Box>
        </Card>
      </div>
    );
  };

  renderMainContent = () => {
    const { t } = this.props,
      { demarches, exploitationDemarches, loading } = this.state;

    const demarchesAcces = exploitationDemarches.filter(
      (exploitationDemarche) =>
        demarches.find((demarche) => demarche.idDemarche === exploitationDemarche.idDemarche)?.selectable
    );

    return (
      <Card className="flex-fill ml-2">
        <CardHeader
          title={
            <div className="d-flex justify-between align-items-start">
              <Typography variant="h3">{t("home.procedures", "Démarches")}</Typography>
              {/*
              La personnalisation des démarches est temporairement désactivée
              <IconButton onClick={this.toggleModal}>
                <FontAwesomeIcon icon="pencil-alt" />
              </IconButton>
              */}
            </div>
          }
        />
        <CardContent>
          {loading ? (
            <Grid container spacing={3}>
              {[{}, {}, {}].map((e, i) => (
                <Grid key={i} item xs>
                  <Skeleton />
                </Grid>
              ))}
            </Grid>
          ) : demarchesAcces.length === 0 ? (
            <p>
              {t(
                "home.no-procedures",
                "Vous n'avez pas possibilité d'accéder à une démarche / certification pour le moment, votre accès doit d'abord être approuvé par un administrateur"
              )}
            </p>
          ) : (
            <Grid container spacing={3}>
              {demarchesAcces.map((demarche) => (
                <Grid
                  key={demarche.idDemarche}
                  item
                  xs={12}
                  md={6}
                  xl={4}
                  className="align-items-stretch"
                  style={{ minHeight: "5rem" }}
                >
                  <Tooltip title={demarche.libelle}>
                    <Button
                      component={Link}
                      to={`/demarches/${demarche.code}`}
                      color="primary"
                      variant="contained"
                      size="large"
                      className="h-100 w-100"
                    >
                      <Typography variant="h5" noWrap>
                        {demarche.libelle}
                      </Typography>
                    </Button>
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
          )}
        </CardContent>
      </Card>
    );
  };

  renderModal = () => {
    const {
      showModal,
      modalData: { selected },
      demarches,
    } = this.state;

    const handleToggle = (value) => {
      if (selected.findIndex((v) => v.idDemarche === value.idDemarche) === -1) {
        this.setState({ modalData: { selected: [...selected, value] } });
      } else {
        this.setState({ modalData: { selected: selected.filter((i) => i.idDemarche !== value.idDemarche) } });
      }
    };

    return (
      <SigaModal
        open={showModal}
        onClose={this.toggleModal}
        title={<Typography variant="h3">Personnaliser les certifications</Typography>}
      >
        <List>
          {demarches.map((demarche) => (
            <ListItem
              key={demarche.idDemarche}
              disabled={!demarche.selectable}
              dense
              button
              onClick={() => handleToggle(demarche)}
            >
              <ListItemIcon>
                <Checkbox
                  color="primary"
                  edge="start"
                  checked={selected.findIndex((d) => d.idDemarche === demarche.idDemarche) !== -1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={demarche.libelle} />
            </ListItem>
          ))}
        </List>
        <CardActions className="mt-1">
          <Button type="button" color="primary" variant="contained" onClick={this.updateExploitationDemarches}>
            Valider
          </Button>
          <Button type="button" variant="contained" onClick={this.toggleModal} className="ml-1">
            Annuler
          </Button>
        </CardActions>
      </SigaModal>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.renderTitle()}
        <div className="flex-fill d-flex">
          {this.renderSidebar()}
          {this.renderMainContent()}
          {this.renderModal()}
        </div>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), withTheme, connect(mapStateToProps), withRouter)(Home);
