import React from "react";
import Indicator from "../../Indicator";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { MenuItem, TextField } from "@material-ui/core";

class ODG extends React.Component {
  render() {
    const {
      indicateur,
      onChangeIndicator,
      sousRubriqueIndex,
      libelleEnglobantIndex,
      changeIndicateur,
      valueInputProps,
      computedFields,
      demarche,
    } = this.props;

    const listeOrganismes = [
      {
        libelle:
          "LA n°03-81 « Viande fraîche de veau nourri par tétée au pis pouvant recevoir une alimentation complémentaire liquide »",
        value: "LA-03-81",
      },
      {
        libelle:
          "LA n°08-13 « Viande fraîche de veau nourri par tétée au pis pouvant recevoir une alimentation complémentaire liquide »",
        value: "LA-08-13",
      },
      {
        libelle: "LA n°08-93 « Veau fermier lourd élevé sous la mère et complémenté aux céréales »",
        value: "LA-09-93",
      },
      {
        libelle:
          "LA n°20-92 « Viande fraîche de veau nourri par tétée au pis pouvant recevoir une alimentation complémentaire liquide »",
        value: "LA-20-92",
      },
      {
        libelle: "LA n°22-89 « Viande fraîche de veau nourri au lait entier »",
        value: "LA-22-89",
      },
      {
        libelle: "LA n°30-99 « Viande fraîche de veau nourri au lait entier »",
        value: "LA-30-99",
      },
      {
        libelle: "IGP « Rosée des Pyrénées Catalanes »",
        value: "IGP",
      },
      {
        libelle: "Engagé en Agriculture Biologique",
        value: "EAB",
      },
      {
        libelle: "",
        value: "",
      },
    ];

    const valueRendered = (
      <TextField
        fullWidth={false}
        InputProps={valueInputProps}
        select
        value={indicateur.valeur.value}
        style={{
          fontWeight: indicateur.afficherGras ? "bold" : "normal",
          width: "20rem",
        }}
        disabled={computedFields.includes(indicateur.codeNational)}
        onChange={(event) =>
          changeIndicateur(
            { ...indicateur, valeur: { value: event.target.value } },
            sousRubriqueIndex,
            libelleEnglobantIndex
          )
        }
      >
        {listeOrganismes.map((element) => (
          <MenuItem key={element.value} value={element.value}>
            {element.libelle}
          </MenuItem>
        ))}
      </TextField>
    );

    return (
      <Indicator indicateur={indicateur} onChange={onChangeIndicator} demarche={demarche}>
        {valueRendered}
      </Indicator>
    );
  }
}

export default compose(withTranslation())(ODG);
