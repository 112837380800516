import React from "react";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { AppBar, Button, Typography } from "@material-ui/core";
import FooterToolbar from "./FooterToolbar";
import network from "../../../actions/external/network";
import SigaModal from "../../common/SigaModal";
import { toastr } from "react-redux-toastr";
import CguForm from "../Cgu/CguForm";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  app: state.app,
  auth: state.auth,
});

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCguModal: false,
      cgu: {},
    };
  }

  componentDidMount() {
    // load exp activities
    this.loadData();
  }

  toggleCguModal = () => {
    this.setState((prevState) => ({
      showCguModal: !prevState.showCguModal,
    }));
  };

  acceptCgu = async (values) => {
    const { t } = this.props;
    const { cgu } = this.state;
    try {
      await network.fetch(`/api/cgu/${cgu.idCgu}?isaccept=${values.cgu.isAcceptCgu}`, {
        method: "POST",
      });

      toastr.success("OK");
      this.setState({ showCguModal: false });
    } catch (error) {
      toastr.error(t("error", "Erreur"), error.message);
    }
  };

  renderCguModal = () => {
    const { showCguModal, cgu } = this.state;
    return (
      <React.Fragment>
        <SigaModal
          open={showCguModal}
          showCloseButton={false}
          heightModal={600}
          title={<Typography variant="h3">Conditions générales d'utilisation</Typography>}
        >
          <CguForm cgu={cgu} showCguModal={this.acceptCgu} onSubmit={this.acceptCgu} />
        </SigaModal>
      </React.Fragment>
    );
  };

  loadData = async () => {
    const { t, auth } = this.props;
    try {
      const [cgu] = await Promise.all([network.fetch(`/api/cgu?idUtilisateur=${auth.idUtilisateur}`)]);
      this.setState({
        showCguModal: true,
        cgu,
      });
    } catch (error) {
      console.error("an error occured while loading exploitation activities", error);
      toastr.error(t("error", "Erreur"), error.message);
    }
  };

  render() {
    const { cgu } = this.state;
    return (
      <React.Fragment>
        {Object.keys(cgu).length !== 0 && this.renderCguModal()}
        <AppBar position="static">
          <FooterToolbar className={"justify-center"}>
            <Button
              component={Link}
              to="/"
              color="inherit"
              style={{ borderRight: "1px solid #ffffff", borderRadius: "0", fontSize: "10px" }}
            >
              Accueil
            </Button>
            <Button component={Link} to="/cgu" color="inherit" style={{ fontSize: "10px" }}>
              CGU
            </Button>
          </FooterToolbar>
        </AppBar>
      </React.Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps)(withTranslation()(Footer)));
