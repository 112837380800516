import React from "react";
import { IconButton, MenuItem, Select, Switch, TableCell, TableRow, TextField } from "@material-ui/core";
import propTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withTheme } from "@material-ui/core/styles";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import NumberInput from "../../../../../../common/NumberInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class AssolementOadPacRow extends React.Component {
  render() {
    const {
      culture,
      assolement,
      culturesOptions,
      precisionsOptions,
      handlePrecisionChange,
      handleCultureChange,
      handleListeInterRang,
      handleListeFourrage,
      handleProductionSemence,
      handleIsLabouree,
      handleSurfaceAdmissibleChange,
      onKeyDown,
      deleteLine,
    } = this.props;

    const filterOptions = createFilterOptions({
      matchFrom: "start",
    });

    const listeInterRang = [
      {
        libelle: "1 inter-rang sur 2",
        value: "1_INTER_RANG_SUR_2",
      },
      {
        libelle: "1 inter-rang sur 3",
        value: "1_INTER_RANG_SUR_3",
      },
      {
        libelle: "1 inter-rang sur 4",
        value: "1_INTER_RANG_SUR_4",
      },
      {
        libelle: "1 inter-rang sur 5",
        value: "1_INTER_RANG_SUR_5",
      },
      {
        libelle: "1 inter-rang sur 6",
        value: "1_INTER_RANG_SUR_6",
      },
      {
        libelle: "2 inter-rangs sur 3",
        value: "2_INTER_RANGS_SUR_3",
      },
      {
        libelle: "2 inter-rangs sur 5",
        value: "2_INTER_RANGS_SUR_5",
      },
      {
        libelle: "2 inter-rangs sur 7",
        value: "2_INTER_RANGS_SUR_7",
      },
      {
        libelle: "1 inter-rang sur 9",
        value: "1_INTER_RANG_SUR_9",
      },
      {
        libelle: "3 inter-rangs sur 4",
        value: "3_INTER_RANGS_SUR_4",
      },
      {
        libelle: "3 inter-rangs sur 5",
        value: "3_INTER_RANGS_SUR_5",
      },
      {
        libelle: "4 inter-rangs sur 5",
        value: "4_INTER_RANGS_SUR_5",
      },
      {
        libelle: "Tous les inter-rangs",
        value: "TOUS_LES_INTER_RANGS",
      },
    ];

    const listeFourrage = [
      {
        libelle: "Commercialisation",
        value: "COMMERCIALISATION",
      },
      {
        libelle: "Autoconsommation",
        value: "AUTOCONSOMMATION",
      },
    ];

    const labour = [
      {
        libelle: "Labour",
        value: "LABOUR",
      },
      {
        libelle: "Aucun labour",
        value: "AUCUN LABOUR",
      },
    ];

    return (
      <TableRow style={{ verticalAlign: "top" }}>
        <TableCell style={{ width: "29rem" }}>
          <Autocomplete
            value={culture}
            options={[...culturesOptions, culture]}
            filterOptions={filterOptions}
            getOptionLabel={(culture) =>
              culture != null ? (!culture.estMajeure ? culture.libelle + " *" : culture.libelle) : ""
            }
            getOptionSelected={(option, value) => option?.cle === value?.cle}
            onChange={handleCultureChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </TableCell>
        <TableCell>
          <Select
            value={assolement.precision?.cle}
            variant="outlined"
            disabled={!culture || precisionsOptions.length < 2}
            style={{ width: "17rem" }}
            onChange={handlePrecisionChange}
          >
            {culture &&
              precisionsOptions.length > 1 &&
              precisionsOptions?.map((p) => (
                <MenuItem key={p.cle} value={p.cle}>
                  {p.libelle}
                </MenuItem>
              ))}
          </Select>
        </TableCell>
        <TableCell style={{ width: "5rem" }}>
          <Switch color={"primary"} onChange={handleProductionSemence} checked={assolement.productionSemence} />
        </TableCell>

        <TableCell style={{ width: "11rem" }}>
          <TextField select defaultValue="" value={assolement.isLabouree} onChange={handleIsLabouree}>
            <MenuItem value={""}>
              <em>Sélectionner</em>
            </MenuItem>
            {labour.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.libelle}
              </MenuItem>
            ))}
          </TextField>
        </TableCell>

        <TableCell style={{ width: "11rem" }}>
          <TextField select defaultValue="" value={assolement.interRangs} onChange={handleListeInterRang}>
            <MenuItem value={""}>
              <em>Sélectionner</em>
            </MenuItem>
            {listeInterRang.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.libelle}
              </MenuItem>
            ))}
          </TextField>
        </TableCell>
        <TableCell style={{ width: "6rem" }}>
          <TextField select defaultValue="" value={assolement.fourrage} onChange={handleListeFourrage}>
            <MenuItem value={""}>
              <em>Sélectionner</em>
            </MenuItem>
            {listeFourrage.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.libelle}
              </MenuItem>
            ))}
          </TextField>
        </TableCell>
        <TableCell style={{ width: "3rem" }}>
          <NumberInput
            numberOfDecimals={2}
            value={assolement.surfaceAdmissible}
            variant="outlined"
            onChange={handleSurfaceAdmissibleChange}
            onKeyDown={onKeyDown}
            disabled={!culture}
            error={!!culture && !!assolement.surfaceAdmissible <= 0}
            helperText={!!culture && assolement.surfaceAdmissible <= 0 ? "Doit être supérieur à 0" : ""}
          />
        </TableCell>
        <TableCell style={{ width: "1rem" }}>
          <IconButton tabIndex={-1} onClick={deleteLine}>
            <FontAwesomeIcon icon="trash-alt" />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}

AssolementOadPacRow.propTypes = {
  assolement: propTypes.shape({
    idCulture: propTypes.number,
    surface: propTypes.oneOfType([propTypes.number, propTypes.string]),
    surfaceAdmissible: propTypes.oneOfType([propTypes.number, propTypes.string]),
    productionSemence: propTypes.bool,
  }).isRequired,
  doubleRow: propTypes.bool,
  culturesOptions: propTypes.array.isRequired,
  handleCultureChange: propTypes.func,
  onKeyDown: propTypes.func,
  onChange: propTypes.func,
  deleteLine: propTypes.func,
  handleListeInterRang: propTypes.func,
  handleListeFourrage: propTypes.func,
  handleProductionSemence: propTypes.func,
  handleIsLabouree: propTypes.func,
  handleSurfaceAdmissibleChange: propTypes.func,
};
AssolementOadPacRow.defaultProps = {
  onKeyDown: () => {},
  doubleRow: false,
};
export default withTheme(withTranslation()(AssolementOadPacRow));
