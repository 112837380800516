import React from "react";
import { Form, withFormik, Field } from "formik";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Button, Grid, FormControlLabel } from "@material-ui/core";
import PropTypes from "prop-types";

class CguForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
  }

  render() {
    const { t, cgu, values } = this.props;

    return (
      <Form className="d-flex flex-column align-items-start">
        <Grid item xs className="mb-1">
          <span style={{ whiteSpace: "pre-line" }}>{cgu.texte}</span>
        </Grid>

        <FormControlLabel
          control={<Field type="checkbox" name="cgu.isAcceptCgu" color="primary" />}
          label={t("cgu.accept", "Accepter les conditions générales d'utilisation")}
          className="mb-1"
        />
        <Button disabled={!values.cgu.isAcceptCgu} color="primary" variant="contained" type="submit" className="mt-1">
          {t("validate", "Valider")}
        </Button>
      </Form>
    );
  }
}

const handleSubmit = async (values, { props, state, setSubmitting }) => {
  await props.onSubmit(values);
  setSubmitting(false);
};

CguForm.propTypes = {
  cgu: PropTypes.object.isRequired,
  showCguModal: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default withRouter(
  withTranslation()(
    withFormik({
      handleSubmit: handleSubmit,
    })(CguForm)
  )
);
