import React from "react";
import { withStyles } from "@material-ui/styles";
import { Toolbar } from "@material-ui/core";

class FooterToolbar extends React.Component {
  render() {
    const { children, classes, className } = this.props;
    return (
      <Toolbar classes={classes} className={className}>
        {children}
      </Toolbar>
    );
  }
}

export default withStyles((theme) => ({
  root: {
    background: `${theme.palette.third.main}`,
    minHeight: "40px",
  },
}))(FooterToolbar);
