import { getRandomString, getSha256base64UrlEncode } from "../scripts/utils";
import network from "./external/network";
import { logout, setAuthentication, setUserInfos } from "./auth";
import { loadUniqueExploitation } from "./exploitation";

export const SET_PARTNER_INFOS = "SET_PARTNER_INFOS";
export const setPartnerInfos = (partnerInfos) => ({
  type: SET_PARTNER_INFOS,
  partnerInfos,
});

export const SET_CODE_VERIFIER_AND_STATE = "SET_CODE_VERIFIER_AND_STATE";
export const setCodeVerifierAndState = (codeVerifier, state) => ({
  type: SET_CODE_VERIFIER_AND_STATE,
  codeVerifier,
  state,
});

export const getPartnerInfos = (partner) => {
  return async (dispatch) => {
    let response = await fetch(`${partner.issuer}${partner.oidcPath}/.well-known`);

    if (!response.ok) {
      throw new Error();
    }
    response = await response.json();

    dispatch(
      setPartnerInfos({
        ...partner,
        wellKnown: response,
      })
    );
  };
};

export const loginWithInternalAccount = (username, password) => {
  return async (dispatch) => {
    const response = await network.fetch(`/api/authentification/utilisateur`, {
      method: "POST",
      body: JSON.stringify({
        username: username,
        password: password,
      }),
      isAuth: true,
    });

    await dispatch(loginSuccess(response));
  };
};

const loginSuccess = (response) => {
  return async (dispatch) => {
    const authorizationHeader = response.headers.get("authorization");
    const token = authorizationHeader.substring("Bearer".length).trim();
    const userInfos = await response.json();

    dispatch(logout()); // suppression des anciennes données
    dispatch(setAuthentication({ token, idUtilisateur: userInfos.idUtilisateur }));
    await dispatch(loadUniqueExploitation());
    dispatch(setUserInfos(userInfos));
  };
};

export const getExternalAuthorizationCode = () => {
  return async (dispatch, getState) => {
    const codeVerifier = getRandomString(40);
    const codeChallenge = getSha256base64UrlEncode(codeVerifier);
    const state = getRandomString(40);

    dispatch(setCodeVerifierAndState(codeVerifier, state));

    const partnerInfos = getState().login.partnerInfos;
    const authorizationEndpoint = partnerInfos.wellKnown.authorization_endpoint;
    const paramsUrl = new URLSearchParams({
      client_id: partnerInfos.oidcWebClientId,
      redirect_uri: document.location,
      response_type: "code",
      scope: "openid , email , profile",
      state: state,
      code_challenge: codeChallenge,
      code_challenge_method: "S256",
    });

    const authorizationUrl = `${authorizationEndpoint}?${paramsUrl}`;
    document.location.href = authorizationUrl;
  };
};

export const getExternalAccessToken = (authorizationCode) => {
  return async (dispatch, getState) => {
    const partnerInfos = getState().login.partnerInfos;
    const tokenEndpoint = partnerInfos.wellKnown.token_endpoint;
    const params = {
      code: authorizationCode,
      code_verifier: getState().login.codeVerifier,
      client_id: partnerInfos.oidcWebClientId,
      redirect_uri: document.location,
      scope: "openid , email , profile",
      grant_type: "authorization_code",
      code_challenge_method: "S256",
      response_type: "token",
    };

    let response = await fetch(tokenEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(params),
    });

    if (!response.ok) {
      throw new Error();
    }

    response = await response.json();

    return response;
  };
};

export const loginWithExternalAccount = (idToken) => {
  return async (dispatch) => {
    const response = await network.fetch(`/api/authentification/utilisateur/application-tierce`, {
      method: "POST",
      body: idToken,
      isAuth: true,
    });

    await dispatch(loginSuccess(response));
  };
};
