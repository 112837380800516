import React from "react";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Router from "./routing/Router";
import { loadAppInfo } from "../actions/app";
import { resetFetching } from "../actions/external/network";
import { Helmet } from "react-helmet";
import { CircularProgress, createMuiTheme, LinearProgress, ThemeProvider } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import ReduxToastr from "react-redux-toastr";
import { loadConfig } from "../actions/config";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowLeft,
  faArrowRight,
  faArrowsAlt,
  faAward,
  faCaretDown,
  faCertificate,
  faCheckSquare,
  faChevronCircleRight,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faClipboardList,
  faCommentAlt,
  faEye,
  faEyeSlash,
  faFileDownload,
  faFileExcel,
  faFileUpload,
  faFilter,
  faFolderOpen,
  faHome,
  faHourglassHalf,
  faKey,
  faMoon,
  faPencilAlt,
  faPlus,
  faPowerOff,
  faPrint,
  faSave,
  faSearch,
  faSitemap,
  faSortDown,
  faSquare,
  faSun,
  faSyncAlt,
  faTimes,
  faTimesCircle,
  faTractor,
  faTrashAlt,
  faUpload,
  faUser,
  faUsers,
  faWrench,
  faArrowAltCircleRight,
  faInfoCircle,
  faLink,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

/**
 * Define icons used inside App
 * (importing each icon manually guarantees minimum bundle size (tree-shaking))
 */
library.add(
  faArrowLeft,
  faChevronDown,
  faSearch,
  faEye,
  faEyeSlash,
  faSortDown,
  faUser,
  faPowerOff,
  faChevronUp,
  faChevronRight,
  faChevronCircleRight,
  faCertificate,
  faFileExcel,
  faHourglassHalf,
  faAward,
  faTimesCircle,
  faTrashAlt,
  faSun,
  faMoon,
  faPencilAlt,
  faCaretDown,
  faSyncAlt,
  faArrowRight,
  faWrench,
  faUsers,
  faTractor,
  faHome,
  faFilter,
  faTimes,
  faClipboardList,
  faSitemap,
  faPlus,
  faCheckSquare,
  faSquare,
  faKey,
  faUpload,
  faFileDownload,
  faCommentAlt,
  faArrowsAlt,
  faFolderOpen,
  faFileUpload,
  faPrint,
  faArrowAltCircleRight,
  faInfoCircle,
  faSave,
  faLink,
  faExclamationTriangle
);

const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};

const mapStateToProps = (state) => ({
  config: state.config,
  fetching: state.network.fetching,
  millesime: state.millesime,
  organisme: state.organisme?.selected,
  auth: state.auth,
  urlOAD: state.app.urlOAD,
});

const mapDispatchToProps = (dispatch) => ({
  loadAppInfo: () => dispatch(loadAppInfo()),
  loadConfig: () => dispatch(loadConfig()),
  resetFetching: () => dispatch(resetFetching()),
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      theme: null,
    };
  }

  createTheme = (config) => {
    return createMuiTheme({
      palette: {
        type: config.darkMode ? "dark" : "light",
        primary: {
          main: config.couleurPrimaire,
        },
        secondary: {
          main: config.couleurSecondaire,
        },
        third: {
          main: config.couleurTertiaire,
        },
        contrastThreshold: 2,
      },
      typography: {
        fontFamily: "roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 400,
        body1: {
          fontSize: 12,
        },
        h1: {
          fontSize: "1.5rem",
          fontWeight: "bold",
        },
        h2: {
          fontSize: "1.3rem",
          fontWeight: "bold",
          textTransform: "uppercase",
        },
        h3: {
          fontSize: "1.2rem",
          fontWeight: "bold",
          display: "inline-block",
          borderBottom: `8px solid ${config.couleurPrimaire}`,
          alignSelf: "start",
        },
        h4: {
          fontSize: "0.9rem",
          fontWeight: "bold",
          textTransform: "uppercase",
        },
        h5: {
          fontSize: "0.9rem",
          fontWeight: "bold",
          textTransform: "none",
        },
        h6: {
          fontSize: "5rem",
          fontWeight: "bold",
          display: "inline-block",
          borderBottom: `8px solid ${config.couleurPrimaire}`,
          alignSelf: "center",
        },
        overline: {
          backgroundColor: config.couleurPrimaire,
          color: "white",
          fontSize: "larger",
          fontWeight: "bolder",
          padding: "0.5rem 0.5rem 0.55rem 0.6rem",
          lineHeight: "1.1rem",
        },
      },
      overrides: {
        MuiPaper: {
          root: {
            backgroundColor: config.darkMode ? "#353535" : grey["100"],
          },
        },
        MuiCard: {
          root: {
            backgroundColor: config.darkMode ? "#424242" : grey["50"],
            borderRadius: "0.5em",
          },
        },
        MuiCardHeader: {
          root: {
            padding: "2em 2em 0 2em",
          },
        },
        MuiAccordion: {
          rounded: {
            borderRadius: "0.5em",
            "&:last-child": {
              borderBottomLeftRadius: "0.5em",
              borderBottomRightRadius: "0.5em",
            },
          },
          root: {
            backgroundColor: config.darkMode ? "#424242" : grey["50"],
            padding: "1em",
            margin: "0em",
            "&$expanded": {
              margin: "0em",
            },
            "&:before": {
              display: "none",
            },
          },
        },
        MuiFormHelperText: {
          root: {
            fontSize: "0.8rem",
          },
        },
        MuiIconButton: {
          root: {
            fontSize: "1.3rem",
          },
        },
        MuiButton: {
          root: {
            minWidth: "0em",
          },
        },
        MuiToolbar: {
          root: {
            backgroundColor: config.darkMode ? "#3b3b3b" : grey["200"],
            maxHeight: "5rem",
          },
        },
        MuiTableHead: {
          root: {
            backgroundColor: config.darkMode ? grey["900"] : grey["200"],
          },
        },
        MuiTableCell: {
          stickyHeader: {
            backgroundColor: config.darkMode ? grey["900"] : grey["100"],
          },
        },
        MuiTab: {
          root: {
            height: "6rem",
            "&$selected": {
              backgroundColor: hexToRGB(config.couleurPrimaire, 0.3),
              fontWeight: "bolder",
            },
          },
        },
        MuiInputBase: {
          root: {
            "&.Mui-disabled": {
              backgroundColor: config.darkMode ? grey["700"] : grey["200"],
              color: config.darkMode ? config.textColor : grey["700"],
            },
          },
          input: {
            '&[inputmode="numeric"]': {
              textAlign: "right",
            },
          },
        },
        MuiAlert: {
          standardError: {
            backgroundColor: "rgba(244,67,54,0.25)",
            color: config.darkMode ? "#ffffff" : "#611A15",
          },
        },
        MuiTooltip: {
          tooltip: {
            fontSize: "0.95em",
          },
        },
      },
      props: {
        MuiFormControl: {
          variant: "outlined",
        },
        MuiTextField: {
          variant: "outlined",
          fullWidth: true,
          InputLabelProps: {
            shrink: true,
          },
        },
        MuiMenu: {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        },
      },
    });
  };

  async componentDidMount() {
    await Promise.all([this.props.loadAppInfo(), this.props.loadConfig()]);
    this.setState({ isLoading: false });
  }

  componentDidUpdate(prevProps, prevState) {
    const isOADPAC = this.props.urlOAD === window.location.origin;
    if (
      prevProps.auth.idOrganismePrincipal !== this.props.auth.idOrganismePrincipal ||
      (isOADPAC && prevProps?.organisme?.idOrganisme !== this.props?.organisme?.idOrganisme)
    ) {
      this.props.loadConfig();
    }

    if (prevProps.config !== this.props.config) {
      this.setState({
        theme: this.createTheme(this.props.config),
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    console.error("App intercepted error : ", error);
  }

  render() {
    const { fetching } = this.props;
    const { isLoading, theme } = this.state;

    return isLoading || theme == null ? (
      <div
        style={{
          minHeight: "100vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <CircularProgress color="inherit" size={150} />
      </div>
    ) : (
      <ThemeProvider theme={this.state.theme}>
        <Helmet>
          <title>{this.props.config.titleSite}</title>
        </Helmet>
        {fetching > 0 && (
          <div
            style={{
              position: "fixed",
              top: 0,
              zIndex: 1200,
              minHeight: "1rem",
              width: "100%",
            }}
          >
            <LinearProgress />
          </div>
        )}
        <Router />
        <ReduxToastr
          newestOnTop={false}
          preventDuplicates
          position="bottom-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          timeOut={3000}
        />
      </ThemeProvider>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
