import React from "react";
import { withTranslation } from "react-i18next";
import styles from "../ChangeExploitation.module.scss";
import {
  Card,
  CardContent,
  CardHeader,
  TextField,
  InputAdornment,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
  Divider,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CitySearch from "../../../common/CitySearch";
import FilterDemarche from "./FilterDemarche";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  app: state.app,
});

export class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quicksearch: props.filters.quicksearch,
      name: props.filters.name,
      city: props.filters.city,
      procedures: props.filters.procedures,
    };
  }

  handleQuicksearch = (event) => {
    this.handleInputChange(event);
    this.props.quicksearch(event.target.value);
  };

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = ["auditResultCertifiable", "auditResultNotCertifiable"].find((n) => n === target.name)
      ? target.checked
      : target.value;
    this.setState({
      [name]: value,
    });
  };

  handleSearch = () => {
    const { quicksearch, ...advancedSearchParams } = this.state;
    this.setState({ quicksearch: "" });
    this.props.search(advancedSearchParams, 0);
  };

  onAddDemarcheFilter = () => {
    this.setState({
      procedures: [
        ...this.state.procedures,
        {
          procedureId: "",
          procedureState: "",
        },
      ],
    });
  };
  onFilterDelete = (i) => () => {
    this.setState({
      procedures: [...this.state.procedures.slice(0, i), ...this.state.procedures.slice(i + 1)],
    });
  };

  onFilterUpdate = (i) => (procedureFilter) => {
    const procedures = this.state.procedures.copyWithin();
    procedures.splice(i, 1, {
      ...procedureFilter,
    });
    this.setState({ procedures });
  };
  render() {
    const { t, loading, certifications, app } = this.props;
    const { quicksearch, name, procedures } = this.state;
    const isOadPac = app.urlOAD === window.location.origin;

    return (
      <div className={styles.sidebar}>
        <Card className="mb-1">
          <CardHeader
            title={
              <Typography variant="h3">{t("change-exploitation.quicksearch.title", "Recherche rapide")}</Typography>
            }
          />

          <CardContent>
            <form noValidate className={styles.quicksearch} onSubmitCapture={(e) => e.preventDefault()}>
              <TextField
                placeholder={t("change-exploitation.quicksearch.placeholder", "Entrez un terme...")}
                variant="outlined"
                name="quicksearch"
                value={quicksearch}
                onChange={this.handleQuicksearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon="search" />
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </CardContent>
        </Card>
        <Accordion>
          <AccordionSummary expandIcon={<FontAwesomeIcon icon="chevron-down" className={styles.chevronIcon} />}>
            <Typography variant="h3" component="h2">
              {t("change-exploitation.advanced-search.title", "Recherche avancée")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <form noValidate className={styles.advancedSearch}>
              <TextField
                label={t("change-exploitation.advanced-search.name", "Raison sociale")}
                variant="outlined"
                name="name"
                value={name}
                onChange={this.handleInputChange}
                className="mb-1"
              />
              <CitySearch
                name="city"
                onChange={({ value }) =>
                  this.handleInputChange({
                    target: {
                      name: "city",
                      value: value ? value.idcommune : "",
                    },
                  })
                }
                className={isOadPac && "my-1"}
              />
              {!isOadPac && (
                <React.Fragment>
                  <Typography variant="h3" component="h5" className="my-2">
                    {t("change-exploitation.advanced-search.procedure", "Gestion des démarches")}
                  </Typography>

                  {procedures.map((elem, i) => (
                    <React.Fragment key={`demarche-${i}`}>
                      <FilterDemarche
                        filter={elem}
                        certifications={certifications}
                        delete={this.onFilterDelete(i)}
                        update={this.onFilterUpdate(i)}
                      />
                      {i + 1 !== procedures.length && <Divider className="my-1" />}
                    </React.Fragment>
                  ))}
                  <Button
                    className="align-self-center mb-1"
                    color="primary"
                    onClick={this.onAddDemarcheFilter}
                    disabled={procedures.findIndex((p) => p.procedureId === "") !== -1}
                  >
                    {t("change-exploitation.advanced-search.add-filter", "Ajouter un filtre")}
                  </Button>
                </React.Fragment>
              )}
              <Button disabled={loading} color="primary" variant="contained" onClick={this.handleSearch}>
                {t("change-exploitation.advanced-search.search", "Rechercher")}
              </Button>
            </form>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(withTranslation()(Filters)));
