import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AdminTable from "../Table/AdminTable";
import { Box, Typography, IconButton, Tooltip, Switch } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import network from "../../../actions/external/network";
import { toastr } from "react-redux-toastr";
import AdminTableHead from "../Table/AdminTableHead";
import { UtilisateurModal } from "./Utilisateur";
import { AccesModal } from "../Acces/Acces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { roles } from "../../common/codes";
import confirm from "../../common/confirm";

const mapStateToProps = (state) => ({
  auth: state.auth,
  app: state.app,
});

class Utilisateurs extends React.Component {
  config = {
    columns: [
      {
        id: "prenom",
        label: "Prénom",
      },
      {
        id: "nom",
        label: "Nom",
      },
      {
        id: "email",
        label: "Email",
      },
      {
        id: "typeUtilisateur",
        label: "Type utilisateur",
      },
      {
        id: "organismes",
        label: "Organismes",
      },
      {
        id: "actions",
        label: "",
        type: AdminTableHead.columnsTypes.ACTION,
      },
    ],
  };

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();

    this.state = {
      data: [],
      count: 0,
      openModalUtilisateur: false,
      openModalAcces: false,
      idUtilisateurModal: null,
      showOnlyUsersWithNoDemarche: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.showOnlyUsersWithNoDemarche !== this.state.showOnlyUsersWithNoDemarche) {
      this.tableRef.current.loadData();
    }
  }

  onSwitchShowOnlyUsersWithNoDemarche = (event) => {
    this.setState({
      showOnlyUsersWithNoDemarche: event.target.checked,
    });
  };

  loadData = async ({ paramsUrl }) => {
    const { t } = this.props;
    const { showOnlyUsersWithNoDemarche } = this.state;
    paramsUrl.append("showOnlyUsersWithNoDemarche", showOnlyUsersWithNoDemarche);
    try {
      const response = await network.fetch(`/api/utilisateurs?${paramsUrl}`);
      this.setState({
        data: response.data,
        count: response.count,
      });
    } catch (error) {
      toastr.error(t("error", "Erreur"), "Erreur lors du chargement des données");
    }
  };

  onSelect = (id) => {
    this.setState({
      openModalUtilisateur: true,
      idUtilisateurModal: id,
    });
  };

  onHandleAcces = (id) => {
    this.setState({
      openModalAcces: true,
      idUtilisateurModal: id,
    });
  };

  onCreate = () => {
    this.setState({
      openModalUtilisateur: true,
      idUtilisateurModal: null,
    });
  };

  onDelete = async (ids, event) => {
    event.stopPropagation();
    const { t } = this.props;

    if (!(await confirm(t("admin.users.delete-confirm", "Êtes-vous sûr de vouloir supprimer cet utilisateur ?")))) {
      return;
    }

    try {
      await Promise.all(
        ids.map((id) =>
          network.fetch(`/api/utilisateurs/${id}`, {
            method: "DELETE",
          })
        )
      );

      this.tableRef.current.loadData();

      toastr.success("OK", t("admin.users.delete-success", "Compte supprimé avec succès"));
    } catch (error) {
      toastr.error(
        t("error", "Erreur"),
        t("admin.users.delete-error", "Une erreur est survenue lors de la suppression")
      );
    }
  };

  getRows = () => {
    const { t } = this.props;
    return this.state.data.map((aData) => {
      const canEditOrRemove =
        this.props.auth.role === roles.SUPERADMIN ||
        this.props.auth.idOrganismePrincipal === aData.idOrganismePrincipal ||
        aData.role === roles.AGRICULTEUR;

      return {
        ...aData,
        typeUtilisateur: aData.typeUtilisateur.libelle,
        organismes: [aData.organismePrincipal?.nom, aData.organismeSecondaire?.nom]
          .filter((value) => value != null)
          .join(", "),
        actions: (
          <React.Fragment>
            <Tooltip title={t("admin.edit", "Modifier")}>
              <IconButton onClick={() => this.onSelect(aData.idUtilisateur)}>
                <FontAwesomeIcon icon="pencil-alt" />
              </IconButton>
            </Tooltip>
            {canEditOrRemove && (
              <Tooltip title={t("admin.handle-access", "Gérer les accès")}>
                <IconButton onClick={() => this.onHandleAcces(aData.idUtilisateur)}>
                  <FontAwesomeIcon icon="key" />
                </IconButton>
              </Tooltip>
            )}
            {canEditOrRemove && this.props.auth.idUtilisateur !== aData.idUtilisateur && (
              <Tooltip title={t("admin.delete", "Supprimer")}>
                <IconButton onClick={(event) => this.onDelete([aData.idUtilisateur], event)}>
                  <FontAwesomeIcon icon="trash-alt" />
                </IconButton>
              </Tooltip>
            )}
          </React.Fragment>
        ),
      };
    });
  };

  afterSubmitFromModal = () => {
    this.tableRef.current.loadData();
    this.handleCloseModal();
  };

  handleCloseModal = () => {
    this.setState({
      openModalUtilisateur: false,
      openModalAcces: false,
      idUtilisateurModal: null,
    });
  };

  renderSwitchUserWitoutDemarche = () => {
    const { showOnlyUsersWithNoDemarche } = this.state;
    return (
      <React.Fragment>
        <Box className="d-flex">
          <Switch
            checked={showOnlyUsersWithNoDemarche}
            color={"primary"}
            onChange={this.onSwitchShowOnlyUsersWithNoDemarche}
          />
          <p>Afficher les utilisateurs sans démarches</p>
        </Box>
      </React.Fragment>
    );
  };

  render() {
    const { t } = this.props;
    const { app } = this.props;
    const isOADPAC = app.urlOAD === window.location.origin;
    let canCreate = true;
    if (isOADPAC) {
      canCreate =
        this.props.auth.role === roles.SUPERADMIN ||
        (this.props.auth.role === roles.ADMIN_ORGANISME && this.props.auth.accessCreationUtilisateur);
    }

    return (
      <React.Fragment>
        <Box className="ml-2 w-100">
          <Typography variant="h3" className="mb-2">
            {t("users", "Utilisateurs")}
          </Typography>
          <AdminTable
            idColumn="idUtilisateur"
            columns={this.config.columns}
            rows={this.getRows()}
            count={this.state.count}
            loadData={this.loadData}
            onCreate={this.onCreate}
            ref={this.tableRef}
            canCreate={canCreate}
            renderHeaderOptions={this.renderSwitchUserWitoutDemarche}
          />
        </Box>
        <UtilisateurModal
          showModal={this.state.openModalUtilisateur}
          onCloseModal={this.handleCloseModal}
          idUtilisateur={this.state.idUtilisateurModal}
          afterSubmit={this.afterSubmitFromModal}
        />
        <AccesModal
          showModal={this.state.openModalAcces}
          onCloseModal={this.handleCloseModal}
          idUtilisateur={this.state.idUtilisateurModal}
          afterSubmit={this.afterSubmitFromModal}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps, null)(withTranslation()(Utilisateurs)));
