import React from "react";
import NumberInput from "../../../../../common/NumberInput";
import Indicator from "../../Indicator";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Switch } from "@material-ui/core";

class PartsSocialesAssocies extends React.Component {
  render() {
    const {
      indicateur,
      onChangeIndicator,
      sousRubriqueIndex,
      libelleEnglobantIndex,
      changeIndicateur,
      valueInputProps,
      computedFields,
      demarche,
    } = this.props;

    const valueRendered = (
      <div className="d-flex align-items-center">
        <NumberInput
          fullWidth={false}
          value={indicateur.valeur.value?.partsSociales ?? ""}
          InputLabelProps={{
            style: { fontWeight: indicateur.afficherGras ? "bold" : "normal" },
          }}
          style={{ maxWidth: "8rem" }}
          InputProps={valueInputProps}
          disabled={computedFields.includes(indicateur.codeNational)}
          onChange={(newValeur) =>
            changeIndicateur(
              {
                ...indicateur,
                valeur: {
                  value: {
                    ...indicateur.valeur.value,
                    partsSociales: newValeur === "" ? null : Number(newValeur),
                  },
                },
              },
              sousRubriqueIndex,
              libelleEnglobantIndex
            )
          }
          numberOfDecimals={2}
        />

        <div className="ml-2">
          <span style={{ alignSelf: "center" }}>Non actif</span>
          <Switch
            checked={indicateur.valeur.value?.isActif}
            color={"primary"}
            onChange={(event) =>
              changeIndicateur(
                {
                  ...indicateur,
                  valeur: {
                    value: {
                      ...indicateur.valeur.value,
                      isActif: event.target.checked,
                    },
                  },
                },
                sousRubriqueIndex,
                libelleEnglobantIndex
              )
            }
          />
          <span style={{ alignSelf: "center" }}>Actif</span>
        </div>
      </div>
    );

    return (
      <Indicator indicateur={indicateur} onChange={onChangeIndicator} demarche={demarche}>
        {valueRendered}
      </Indicator>
    );
  }
}

export default compose(withTranslation())(PartsSocialesAssocies);
