import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { debounce, Grid, Typography } from "@material-ui/core";
import { setSelectedExploitation } from "../../../actions/exploitation";
import { withRouter } from "react-router-dom";
import "./ChangeExploitation.module.scss";
import Filters from "./Filters/Filters";
import Results from "./Results/Results";
import network from "../../../actions/external/network";
import { compose } from "redux";
import { toastr } from "react-redux-toastr";

const mapStateToProps = (state) => ({
  millesime: state.millesime.selected,
  organisme: state.organisme.selected,
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedExploitation: (exploitation) => dispatch(setSelectedExploitation(exploitation)),
});

class ChangeExploitation extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      result: [],
      demarches: [],
      filteredResult: [],
      filters: {
        quicksearch: "",
        name: "",
        city: "",
        procedures: [
          {
            procedureId: "",
            procedureState: "",
          },
        ],
      },
      loading: false,
      total: 0,
      offset: 0,
      orderBy: "raisonSociale",
      order: "asc",
    };
  }

  handleSelectExploitation = async (exploitation) => {
    //récupération des informations non contenues dans les exploitations affichées
    const [exploitationInfo, departement, exploitationInformation] = await Promise.all([
      network.fetch(`/api/exploitations/${exploitation.idExploitation}`),
      network.fetch(`/api/entites/commune?idCommune=${exploitation.idCommune}`),
      network.fetch(
        `/api/exploitations/${exploitation.idExploitation}/infos?millesime=${this.props.millesime.idMillesime}`
      ),
    ]);
    const [region] = await Promise.all([network.fetch(`/api/entites/parente?idEntite=${departement.identite}`)]);
    const selectedExploitation = {
      ...exploitation,
      ...exploitationInfo,
      departement: departement.libelleentite,
      region: region.libelleentite,
      irrigation: exploitationInformation.irrigation,
    };
    await this.props.setSelectedExploitation(selectedExploitation);
    // go to home
    this.props.history.push("/");
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadDemarches();
    this.onSearch();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { app } = this.props;
    const isOADPAC = app.urlOAD === window.location.origin;
    if (
      prevProps.millesime.idMillesime !== this.props.millesime.idMillesime ||
      prevState.orderBy !== this.state.orderBy ||
      prevState.order !== this.state.order ||
      (isOADPAC && prevProps?.organisme?.idOrganisme !== this.props?.organisme?.idOrganisme)
    ) {
      this.onSearch(null, 0, true);
    }
  }

  loadDemarches = async () => {
    const { app } = this.props;
    const isOADPAC = app.urlOAD === window.location.origin;
    try {
      const demarches = await network.fetch(`/api/demarches?isOadPac=${isOADPAC}`);
      this.setState({ demarches });
    } catch (e) {}
  };

  onQuicksearch = debounce((term) => {
    const lowercaseTerm = term.toLowerCase();
    // filter quickly through results
    this.setState({
      filteredResult:
        term.length > 0
          ? this.state.result.filter(
              (exploitation) =>
                exploitation.raisonSociale.toLowerCase().includes(lowercaseTerm) ||
                exploitation.pacage?.toLowerCase().includes(lowercaseTerm) ||
                exploitation.siret.toLowerCase().includes(lowercaseTerm) ||
                exploitation.adresse.toLowerCase().includes(lowercaseTerm) ||
                exploitation.adresse2.toLowerCase().includes(lowercaseTerm) ||
                exploitation.nomCommune.toLowerCase().includes(lowercaseTerm) ||
                exploitation.demarches.findIndex((demarche) =>
                  demarche.libelle.toLowerCase().includes(lowercaseTerm)
                ) !== -1
            )
          : this.state.result,
    });
  }, 500);

  onSearch = async (filters = null, reqOffset = 0, resort = false) => {
    const { app } = this.props;
    const isOADPAC = app.urlOAD === window.location.origin;
    this.setState({
      filters: filters !== null ? filters : this.state.filters,
      loading: true,
      result: filters !== null ? [] : this.state.result,
      filteredResult: [],
    });
    const shouldAppendResult = filters === null && !resort;
    /**
     * state might not be set right after setState,
     * so we spread-merge filters and state.filters
     */
    filters = { ...this.state.filters, ...filters };
    try {
      let setFilters = {};
      ["city", "name"].forEach((key) => {
        if (filters[key] !== "") {
          setFilters[key] = filters[key];
        }
      });
      const filteredProcedures = filters.procedures.filter((p) => p.procedureId !== "");
      if (filteredProcedures.length) {
        setFilters.procedures = JSON.stringify(filteredProcedures);
      }
      const urlSearchParams = new URLSearchParams({
        ...setFilters,
        millesime: this.props.millesime.idMillesime,
        offset: reqOffset,
        orderBy: this.state.orderBy,
        order: this.state.order,
      });
      const idOrganisme = isOADPAC ? `&idOrganisme=${this.props.organisme.idOrganisme}` : "";
      const result = await network.fetch(`/api/exploitations/search?${urlSearchParams}${idOrganisme}`);
      const { data, count, offset } = result;
      const formattedResult = [...(shouldAppendResult ? this.state.result : []), ...data];
      this.setState({
        result: [...formattedResult],
        total: count,
        offset,
        loading: false,
        filteredResult: [...formattedResult],
      });
    } catch (error) {
      if (this._isMounted) {
        this.setState({
          result: [],
          total: 0,
          offset: 0,
          orderBy: "raisonSociale",
          order: "asc",
          loading: false,
        });
      }
      toastr.error("Erreur", error.message);
    }
  };

  onLoadMore = async () => {
    this.onSearch(null, this.state.result.length);
  };

  onExport = async () => {
    try {
      const setFilters = {};
      ["city", "name"].forEach((key) => {
        if (this.state.filters[key] !== "") {
          setFilters[key] = this.state.filters[key];
        }
      });
      const filteredProcedures = this.state.filters.procedures.filter((p) => p.procedureId !== "");
      if (filteredProcedures.length) {
        setFilters.procedures = JSON.stringify(filteredProcedures);
      }
      const urlSearchParams = new URLSearchParams({
        ...setFilters,
        millesime: this.props.millesime.idMillesime,
        l: 0,
        limit: 0,
      });
      const { app } = this.props;
      const isOADPAC = app.urlOAD === window.location.origin;
      const idOrganisme = isOADPAC ? `&idOrganisme=${this.props.organisme.idOrganisme}` : "";
      const { data } = await network.fetch(`/api/exploitations/search?${urlSearchParams}${idOrganisme}`);
      const rows = data
        .flatMap(({ demarches, ...rest }) => demarches.map((d) => ({ ...rest, ...d })))
        .map((row) => {
          const elem = document.createElement("tr");
          // filter out columns
          Object.entries(row)
            .filter(
              ([key, value]) =>
                [
                  "raisonSociale",
                  "pacage",
                  "siret",
                  "adresse",
                  "adresse2",
                  "nomCommune",
                  "libelle",
                  "etatDossier",
                  "dateEntree",
                  "dateAudit",
                  "dateCertification",
                  "dateHve1",
                  "dateHve2",
                ].findIndex((e) => e === key) !== -1
            )
            .forEach(([key, value]) => {
              const cell = document.createElement("td");
              cell.innerText = value || "";
              elem.appendChild(cell);
            });
          return elem;
        });
      const thead = document.createElement("thead");
      const tr = document.createElement("tr");
      thead.appendChild(tr);
      [
        this.props.t("change-exploitation.table-headers.name", "Raison Sociale"),
        this.props.t("change-exploitation.table-headers.address", "adresse"),
        this.props.t("change-exploitation.table-headers.address", "adresse2"),
        this.props.t("change-exploitation.table-headers.siret", "SIRET"),
        this.props.t("change-exploitation.table-headers.pacage", "N°Pacage"),
        this.props.t("change-exploitation.table-headers.city", "COMMUNE"),
        this.props.t("procedure.name", "Nom"),
        this.props.t("procedure.procedure-state.name", "Etat du dossier"),
        this.props.t("procedure.date-entree", "Date d'entrée"),
        this.props.t("procedure.date-audit", "Date d'audit"),
        this.props.t("procedure.date-certif", "Date de certification"),
        this.props.t("demarche.date-certification-niveau-1", "Date de certification de niveau 1"),
        this.props.t("demarche.date-certification-niveau-2", "Date de certification de niveau 2"),
      ].forEach((e) => {
        const th = document.createElement("th");
        th.innerText = e;
        tr.appendChild(th);
      });
      const tbody = document.createElement("tbody");
      rows.forEach((row) => tbody.appendChild(row));
      const table = document.createElement("table");
      table.appendChild(thead.cloneNode(true));
      table.appendChild(tbody);
      const html = table.innerHTML;
      const uri = "data:application/vnd.ms-excel;base64,",
        template =
          '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
        base64 = (s) => window.btoa(unescape(encodeURIComponent(s))),
        format = (s, c) => s.replace(/{(\w+)}/g, (m, p) => c[p]);
      const name = "export";
      const ctx = {
        worksheet: name || "Worksheet",
        table: html,
      };
      const a = document.createElement("a");
      a.href = uri + base64(format(template, ctx));
      a.download = "Export.xls";
      document.body.append(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error(error);
    }
  };

  onSort = ({ orderBy, order }) => {
    this.setState({
      orderBy,
      order,
    });
  };

  render() {
    const { t } = this.props;
    const { filters, filteredResult, demarches, loading, total, offset, orderBy, order } = this.state;
    return (
      <React.Fragment>
        <Typography variant="h1" className="pb-2">
          {t("change-exploitation.title", "Changer d'exploitation")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Filters
              filters={filters}
              certifications={demarches}
              loading={loading}
              search={this.onSearch}
              quicksearch={this.onQuicksearch}
            />
          </Grid>
          <Grid item xs={9}>
            <Results
              exploitations={filteredResult}
              selectExploitation={this.handleSelectExploitation}
              exploitationsTotal={total}
              exploitationsOffset={offset}
              export={this.onExport}
              loadMore={this.onLoadMore}
              orderBy={orderBy}
              order={order}
              sort={this.onSort}
              loading={loading}
              millesime={this.props.millesime.idMillesime}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps), withRouter)(ChangeExploitation);
